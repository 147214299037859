import React from 'react'
import { Skeleton } from '@mui/material';
import RatingWithEmoji from '../Helpers/ratingWithEmoji';

const LockedReviews = ({ reviews, loading }) => {
    function formatDate(dateString) {
        const date = new Date(dateString);

        // Format time
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;

        // Format date
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' }); // e.g., "Aug"
        const year = date.getFullYear();

        return `${formattedTime} ${day} ${month} ${year}`;
    }

    const getBorderColor = (rating) => {
        if (rating > 3) {
            return "3px solid #18aee3"
        } else {
            return "3px solid red"
        }
    }

    return (
        <>
            <div className='background_linear_review'></div>
            {
                loading ? <Skeleton variant="rectangular" sx={{ minHeight: "600px", width: '100%' }} /> : reviews?.map((review, index) => (

                    <div className="review_item" style={{ borderLeft: getBorderColor(review?.rating) }} key={index}>
                        <div className="top_head">
                            <div className="locked_left">
                                <img src={review?.source?.logo_src} alt="icon" />
                                <div>
                                    <h3>{review?.reviewerName}</h3>
                                    <h6>{formatDate(review?.publishedDateTime)}</h6>
                                </div>
                            </div>
                            <div className="right">
                                <RatingWithEmoji staticRating={true} rating={review?.rating} anim={false} emojiSize={20} starSize={18} labelSize={55} rating_num={review?.rating} />

                                {/* <div className="stars">
                                    {Array(review?.rating)
                                        .fill(0)
                                        .map((_, i) => (
                                            <img
                                                key={i}
                                                src="/admin-images/locked-star-icon.svg"
                                                width="15"
                                                alt="star icon"
                                            />
                                        ))}
                                </div> */}
                            </div>
                        </div>
                        <div className="review_content">
                            <p>{review?.contentSnippet}</p>
                        </div>
                    </div>))
            }
        </>
    )
}

export default LockedReviews
