import React, {useState, useEffect} from "react"
import { AiFillStar, AiOutlinedStar } from "react-icons/ai"

const RatingWithEmoji = ({staticRating, rating, anim ,emojiSize, starSize, showLabel, labelSize, rating_num}) => {
    const [animate, setAnimate] = useState(false)
    let roundedRatingNum = Math.round(parseFloat(rating_num))
    let ratingNum = 5 - (rating ? rating : 0)

    const getEmoji = (rating) => {
        switch(Math.round(parseFloat(rating))){
            case 1:
                return <img style={{width: emojiSize}} src="/admin-icons/emoji/angry_emoji.png" alt="angry"/>
            case 2:
                return <img style={{width: emojiSize}} src="/admin-icons/emoji/sad_emoji.png" alt="sad"/>
            case 3:
                return <img style={{width: emojiSize}} src="/admin-icons/emoji/bored_emoji.png" alt="bored"/>
            case 4:
                return <img style={{width: emojiSize}} src="/admin-icons/emoji/smiling_emoji.png" alt="smiling"/>
            case 5:
                return <img style={{width: emojiSize}} src="/admin-icons/emoji/happy_emoji.png" alt="happy"/>
            default:
                return ""
        }
    }
    useEffect(() => {
        if(anim){
            setAnimate(true)
        }
    }, [anim])

    if(anim){
        return(
            <div className="rating-with-emoji">
                {showLabel && <span style={{fontSize: labelSize}}>{rating_num}</span>}
                {
                    [...Array(5 - ratingNum)].map((el, i) => {
                        return (
                            <AiFillStar key={`start-filled-${i}`} className={`star ${animate ? 'animate' : 'default'}`} style={{fontSize: starSize}}/>
                        )
                    })
                }
                {
                    [...Array(ratingNum)].map((el, i) => {
                        return (
                            <AiFillStar key={`start-default-${i}`} className='star default' style={{fontSize: starSize}}/>
                        )
                    })
                }
               <div>
                 {staticRating ? getEmoji(rating_num) : getEmoji(rating)}
               </div>
            </div>
        ) 
    }
    if(!anim){
        return(
            <div className="rating-with-emoji">
                {showLabel && <span style={{fontSize: labelSize}}>{rating_num}</span>}
               {
                    [...Array(5 - ratingNum)].map((el, i) => {
                        return (
                            <AiFillStar key={`start-filled-${i}`} className={`star`} style={{fontSize: starSize, color: roundedRatingNum !== 0 ? "#faaf00" : "C5C9CC"}}/>
                        )
                    })
                }
                {
                    [...Array(ratingNum)].map((el, i) => {
                        return (
                            <AiFillStar key={`start-default-${i}`} className='star default' style={{fontSize: starSize}}/>
                        )
                    })
                }
                <div>
                    {staticRating ? getEmoji(rating_num) : getEmoji(rating)}
                </div>
            </div>
        )
    }

}
export default RatingWithEmoji