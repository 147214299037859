import React, {useEffect, useState} from "react"
import { Link, useLocation } from "react-router-dom";
import { elements } from "./helpers/sidebarElements";
import SubItem from "./parts/subnav_item";
import {IoIosArrowForward} from "react-icons/io"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showSidebar, showNavbar } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import LightTooltip from "./helpers/lightTooltip";
import { Skeleton } from "@mui/material";

const Sidebar = ({height}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const [active, setActive] = useState("")
    const [showChilds, setShowChilds] = useState("")
    const [showPages, setShowPage] = useState([])

    const activeOnMob = useSelector(state => state.showSidebarReducer.show)
    const activeOnMobNav = useSelector(state => state.showNavbarReducer.show)

    const locationId = useSelector(state => state.locationSelected.location)
    const user = useSelector(state => state.getUserInfo.user)
    const user_permissions = useSelector(state => state.getUserPermissions.permissions)

    const [filteredElements, setFilteredElements] = useState()

    const toggleSidebarEl = (path, children) => {
        setActive(path)
        setShowChilds((showChilds === path) ? "" : path)

        if(!children){
            dispatch(showSidebar(false))
        }
    }
    
    const toggleNavbar = () => {
        dispatch(showNavbar(!activeOnMobNav))
        dispatch(showSidebar(false))
    }
    
    useEffect(() => {
        elements.forEach(element => {
            if(element.children){
                if(element.children.find(el => el.path === location.pathname || location.pathname.includes(el.path))){
                    setActive(element.path)
                    setShowChilds(element.path)
                }
            } else if (element.path === location.pathname){
                setActive(element.path)
                setShowChilds(element.path)
            }
        })
    }, [location])


    useEffect(() => {
        if (active) {
          const parentDiv = document.querySelector(".parent-ul-container")
          const activeEl = document.querySelector(".nav-item.parent-li-item.active.show");
          if (activeEl) {
            let { top } = activeEl.getBoundingClientRect();
            top -= 70
            parentDiv && parentDiv.scrollTo({ top,  behavior: 'smooth' });
          }
        }
      }, [active]);

    useEffect(() => {
        if(locationId && locationId !== "all"){
            let location_details = user && user.user.locations.filter(loc => loc.id == locationId)
            if(location_details && location_details[0] && (location_details[0].plan == 2 )){
                elements.map(item => {
                    if(item.fullService){
                        if(!showPages.some(el => el === item.key)){
                            setShowPage(prev => [...prev, item.key])
                            if(location.pathname === item.path){
                                navigate("/app/admin")
                            }
                        }
                    }
                })
            } else {
                setShowPage([])
            }
        }
    }, [locationId, user])

    useEffect(() => {
        if(user_permissions){
            let permissions = user_permissions?.data
            let permissionElements = elements.map(el => {
                const childrens = el.children
                if(childrens && childrens.length > 0 ){
                    let filteredChildren = childrens.filter(child => permissions[el.key]?.[child.subKey] === true)
                        if(filteredChildren.length > 0){
                            return {...el, children: filteredChildren}
                        } else {
                            return null
                        }
                } else {
                    if(permissions[el.key] === true){
                        return el
                    } else {
                        return null
                    }
                }
            }).filter(Boolean)
            setFilteredElements(permissionElements)
        }
        // setFilteredElements(elements)
    }, [user_permissions])

    return(
        <div className="new-sys-sidebar">
            <div className="new-sidebar-top" style={{marginTop: height ? height : 0}}>
                <Link to="/app/admin">
                    <img src={"/admin-images/iReview_white.svg"} alt="logo"/>
                </Link>
                <div className="d-flex align-items-center">
                    <img src={"/admin-icons/sidebar/tools-nav.svg"} alt="settings" className="toggle-nav" onClick={() => toggleNavbar()}/>
                    <img src={"/admin-icons/sidebar/toggle-icon-b.png"} alt="toggle" className="toggle-sidebar-icon" onClick={() => {
                        dispatch(showSidebar(!activeOnMob))
                        dispatch(showNavbar(false))
                    }}/>
                </div>
            </div>
            <div className={`sidebar-main-elements ${activeOnMob ? "active" : ""}`}>
                <div className="sidebar-item-list">
                    <ul className="parent-ul-container">
                        {(user_permissions && filteredElements && user) ? filteredElements.map(el => {
                            if(el.fullService && showPages.some(element => element === el.key)){
                                    return(
                                        ""
                                    )
                            } else {
                                return(
                                    <li className={`nav-item parent-li-item ${active === el.path ? "active" : ""} ${showChilds === el.path  ? "show" : ""}`} key={el.key} >
                                        {el.children ?  
                                        <div>
                                            <span className="nav-link navlink-withchildrens" onClick={() => toggleSidebarEl(el.path, el.children)}>
                                            <i className="new-icon-sidebar" style={{backgroundImage: `url(${el.icon})`}}></i>
                                                {t(el.name)}
                                                <IoIosArrowForward/>
                                            </span>
                                            {el.children && 
                                                <SubItem children={el.children}/>
                                            }
                                        </div>
                                        :  
                                        el.external ? <a className="nav-link" href={el.external}><span className="new-icon-sidebar" style={{backgroundImage: `url(${el.icon})`}}></span>{el.name}</a>
                                        : <Link className="nav-link" to={el.path} onClick={() => toggleSidebarEl(el.path)}>
                                            <span className="new-icon-sidebar" style={{backgroundImage: `url(${el.icon})`}}></span>
                                            {t(el.name)}
                                        </Link>
                                        }
                                    </li>
                                )
                            }
                        }) : 
                            <>
                            {
                                [...Array(11)].map((el, i) => (
                                    <Skeleton key={`skeleton-${i}`} style={{width: "87%", height: "40px", transform: "unset", marginBottom: "10px",  marginTop: "10px", marginLeft: "auto", marginRight: "auto"}}/>
                                ))
                            }
                            </>
                        }
                    </ul>
                </div>
                <div className="switch-div-container">
                    <div>
                        <div>
                            <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.irevu.mydev&hl=en&gl=US" target="_blank"><img src="/admin-images/playstore.png" alt="Google PlayStore"/></a>
                            <a href="https://apps.apple.com/us/app/ireview-management/id6443944283" target="_blank"><img src="/admin-images/apple.png" alt="App Store"/></a>
                        </div>
                        <p>Tap once, enjoy forever</p>
                        <h6>Download now!</h6>
                    </div>
                    {/* <span>{t("This is a BETA version of iReview")}</span> */}
                    {/* <a href=" https://irevu.com/index.php">Switch to Classic</a> */}
                    {/* <a href="https://accounts.mydev.com/login?platform=o4124n1on4c1oc4n124nc1adsdlwoooq">Switch to Classic</a> */}
                </div>
            </div>
        </div>  
    )
}
export default Sidebar
