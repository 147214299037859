import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid, FormControl, InputLabel, Select, MenuItem, Box, Rating, Skeleton, Chip, Modal } from '@mui/material'
import { Helmet } from 'react-helmet';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowDown } from "react-icons/io"
import { RiShareForwardFill } from "react-icons/ri";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

// custom component
import ReviewProgressBar from './parts/ReviewProgressBar';
import ReviewList from './parts/ReviewList';
import WriteReview from './parts/WriteReview';
import SmallSelect from '../Helpers/SmallSelect';
import MissingAbout from './parts/MissingAbout';
import AboutAllPhotos from './AboutAllPhotos';
import AddPhotoModal from './AddPhotoModal';
import ShareModal from './ShareModal';

import '../../assets/about.css';
import axios from 'axios';
import { baseUrl, baseUrlDev } from '../Helpers/baseUrl';
import { getLocalUser } from '../TokenControl/parts/useLocalUser';
import UserDropdown from '../Admin/UserDropdown';

// const items = [
//     <div className="item" data-value="1"><img src="/admin-images/about-default-banner.svg" alt="1" /></div>,
//     <div className="item" data-value="2"><img src="/admin-images/default-banner.svg" alt="2" /></div>,
//     <div className="item" data-value="3"><img src="https://s3-media0.fl.yelpcdn.com/educatorphoto/z55ycyxaqyb_r8xd74Mdmg/o.jpg" alt="3" /></div>,
//     <div className="item" data-value="4"><img src="https://s3-media0.fl.yelpcdn.com/educatorphoto/xfKgg4ymAXsJLQB13m8baQ/o.jpg" alt="4" /></div>,
//     <div className="item" data-value="5"><img src="https://s3-media0.fl.yelpcdn.com/educatorphoto/uEAEgC7rnkwHWJ-HrlLvzA/o.jpg" alt="5" /></div>,
// ];

const About = () => {
    let { name } = useParams();
    const [companyData, setCompanyData] = useState({
        name: null,
        location: null,
        logoUrl: null,
        website: null,
        company_id: null,
        longitude: 0,
        latitude: 0,
        working_hours: {
            to: null,
            from: null
        }
    });
    const [reviewStats, setReviewStats] = useState({
        total: 0,
        rating: [0, 0, 0, 0, 0],
        avg: 0
    });
    const [showModal, setShowModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [openReviewModal, setOpenReviewModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [items, setItems] = useState([]);

    const navigation = useNavigate();

    const companyDataSelect = useSelector(state => state.companieAboutReducer.companie)
    const [loading, setLoading] = useState(false)
    const user = localStorage.getItem("user")
    const userState = useSelector(state => state.getUserInfo.user);

    useEffect(() => {
        if (!companyDataSelect) return;
        if (!companyDataSelect?.reviewStars) return;
        //console.log('companyDataSelectcompanyDataSelectcompanyDataSelect:',companyDataSelect.company.categories);

        let ratingArray = [companyDataSelect.reviewStars.stars["1 star"].count,
        companyDataSelect.reviewStars.stars["2 star"].count,
        companyDataSelect.reviewStars.stars["3 star"].count,
        companyDataSelect.reviewStars.stars["4 star"].count,
        companyDataSelect.reviewStars.stars["5 star"].count];

        setCompanyData({
            ...companyData,
            location: companyDataSelect?.company?.address,
            name: companyDataSelect?.company?.name,
            website: companyDataSelect?.company?.website,
            logoUrl: companyDataSelect?.company?.logo,
            working_hours: companyDataSelect?.company?.working_hours,
            email: companyDataSelect?.company?.email,
            longitude: companyDataSelect?.company?.longitude,
            latitude: companyDataSelect?.company?.latitude,
        });

        let avgCalc = ratingArray.reduce((a, c, i) => a += c * (i + 1), 0) / companyDataSelect.reviewStars.total;

        setReviewStats({
            total: companyDataSelect.reviewStars.total,
            rating: ratingArray,
            avg: isNaN(avgCalc) ? 0.0 : avgCalc
        });
    }, [companyDataSelect?.company?.name]);

    const [sortby, setSortby] = useState('latest');
    const handleChangeSort = (event) => {
        setSortby(event.target.value);
    };

    const renderPrevButton = ({ isDisabled }) => {
        return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&lt;</span>;
    };

    const renderNextButton = ({ isDisabled }) => {
        return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&gt;</span>;
    };

    const addShowModalHandler = () => {
        setShowAddModal(true);
        setShowModal(false);
    }

    const getRatingText = (rating) => {
        switch (Math.round(parseFloat(rating))) {
            case 5: return "your customers love your services, keep it up"
            case 4: return "your customers really like your services, and are willing to come back"
            case 3: return "your customers think you need to step up and improve your services"
            case 2: return "your reviewers had a really bad customer experience"
            case 1: return "your customers kinda hate your services"
            default: return ""
        }
    }

    const getEmojiImage = (rating) => {
        if (rating >= 5) {
            return '/admin-images/emojistar5.svg';
        } else if (rating >= 4) {
            return '/admin-images/emojistar4.svg';
        } else if (rating >= 3) {
            return '/admin-images/emojistar3.svg';
        } else if (rating >= 2) {
            return '/admin-images/emojistar2.svg';
        } else {
            return '/admin-images/emojistar1.svg';
        }
    };

    // Get the rating and calculate the number of stars to display
    const rating = companyDataSelect?.company?.totalReviewsAvg ?? 0;
    const starsToShow = Math.floor(rating); // Only show whole stars

    useEffect(() => {
        if (companyDataSelect?.company?.placeId) {
            axios.get(`https://api.irevu.com/api/v1/locations/media/random/${companyDataSelect?.company?.placeId}`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
                .then((res) => {
                    if (res?.data?.data) {
                        const formattedItems = res?.data?.data?.map((item, index) => (
                            <div className="item" data-value={index} key={index}>
                                <img src={item} alt={index} />
                            </div>
                        ));
                        setItems(formattedItems);
                    }
                })
                .catch(err => {
                    console.log(err, 'error');
                })
        }
    }, [companyDataSelect?.company?.placeId]);

    return (
        <>
            <Modal
                open={showModal}
            >
                <AboutAllPhotos
                    setShowModal={setShowModal}
                    items={items}
                    companyDataSelect={companyDataSelect?.company}
                />
            </Modal>
            <Modal
                open={showAddModal}
            >
                <AddPhotoModal
                    setShowAddModal={setShowAddModal}
                    setShowModal={setShowModal}
                />
            </Modal>
            <Modal open={shareModal}>
                <ShareModal setShareModal={setShareModal} companyDataSelect={companyDataSelect?.company} />
            </Modal>

            <WriteReview
                open={openReviewModal}
                setOpen={setOpenReviewModal}
                setSortby={setSortby}
                companyName={companyData.name}
                irevu={companyDataSelect?.company?.is_irevu}
            />

            <div className="about-section">
                <Helmet>
                    <title>Company Details - iReview</title>
                    <meta property="og:title" content="Company Details - iReview" />
                    <meta property="og:url" content={`https://iReview.com/companies/${name}`} />
                </Helmet>
                <MissingAbout />
                <div className='bg-white'>
                    <div className='about-page-header'>
                        <a href="/">
                            <img alt="logo" src={"/admin-images/ireview-by-kore-r.svg"} />
                        </a>
                        {/* {companyDataSelect?.company?.accredited !== 1 && <div>Want to get Accredited? <a href="https://irevu.com/request-demo/">Click Here.</a></div>} */}
                        <div className='right'>
                            <button className='btn write_review_btn' onClick={() => setOpenReviewModal(true)} disabled={!companyData?.name}>Write a Review</button>
                            {user ?
                                <>
                                    <li className="li-as-btn" style={{ marginRight: "10px", listStyle: 'none' }}>
                                        <Link to="/app/admin">Dashboard</Link>
                                    </li>
                                    <UserDropdown user={userState} setLoading={setLoading} />
                                </>
                                :
                                <div className='buttons'>
                                    <Link to='/login' className='login-btn'>Log In</Link>
                                    <Link to='/create' className='sign-up-btn'>Sign Up</Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <section className="intro-section">
                    {/* {companyDataSelect && companyDataSelect.company.banner ? <img className='uploaded-banner' src={companyDataSelect.company.banner} alt="banner" /> : <img src={"/admin-images/about-default-banner.svg"} alt="banner" />} */}
                    <div className="slider-wrapper">
                        {
                            items?.length > 0 ? <AliceCarousel
                                mouseTracking
                                items={items}
                                renderPrevButton={renderPrevButton}
                                renderNextButton={renderNextButton}
                                disableDotsControls
                            />
                                : <div className="item" data-value="1"><img src="/admin-images/about-default-banner.svg" alt="1" /></div>
                        }
                        <div className="details-company">
                            <h2>{companyDataSelect?.company?.name}</h2>
                            <div className='flex_wrapper reviews'>
                                <div className="stars">
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <img
                                            key={index}
                                            src="/admin-images/yellow-star-about.png"
                                            alt="star"
                                            style={{ display: index < starsToShow ? 'inline' : 'none' }} // Show only filled stars
                                        />
                                    ))}
                                </div>
                                {/* <span>{companyDataSelect?.reviews?.data?.length ?? 0} reviews</span> */}
                                <span>{companyDataSelect?.company?.totalReviews ?? 0} reviews</span>
                            </div>
                            <div className='flex_wrapper closed_det'>
                                <h4>
                                    <span>{companyDataSelect?.company?.check_working_hour}</span>
                                    {companyDataSelect?.company?.working_hours?.map(item => <span style={{ color: '#fff' }}>{item}</span>)}
                                </h4>

                                {
                                    items?.length > 0 && <div className='all_photos_btn'>
                                        <button onClick={() => setShowModal(true)}>See all photos</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <div className="section-review">
                    <Grid container spacing={4} className="change-order-mob">
                        <Grid item md={8} xs={12}>
                            <div className="left_top_header_section_review">
                                <div className="buttons">
                                    <button className='btn write_review_btn' onClick={() => setOpenReviewModal(true)} disabled={!companyData?.name}>
                                        <img src="/admin-images/white-star.png" alt="star" />
                                        Write a Review
                                    </button>
                                    {/* <button className='btn' onClick={() => setShowAddModal(true)}>
                                        <img src="/admin-images/ic_outline-photo-camera.svg" alt="camera" />
                                        Add Photo
                                    </button> */}
                                    <button className='btn' onClick={() => setShareModal(true)}>
                                        <RiShareForwardFill />
                                        Share
                                    </button>
                                </div>
                                <div className="rating-review-detail">
                                    <div className='wrapper'>
                                        <h5>{companyDataSelect?.company?.totalReviewsAvg > 0 ? companyDataSelect?.company?.totalReviewsAvg.toFixed(1) : '0'}</h5>
                                        <Rating icon={<img src={"/admin-images/yellow-star-about.png"} className="custom-rating-ic" />} emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-ic" />} name="half-rating-read" value={parseFloat(reviewStats.avg.toFixed(2))} precision={0.5} readOnly style={{ marginRight: '-10px' }} />

                                        {/* <div className='stars-images'>
                                            {Array.from({ length: 5 }, (_, index) => (
                                                <img
                                                    key={index}
                                                    src="/admin-images/yellow-star-about.png"
                                                    alt="star"
                                                    // style={{ opacity: index < starsToShow ? 1 : 0.3 }} // Adjust opacity for unfilled stars
                                                    style={{ display: index < starsToShow ? 'inline' : 'none' }} // Show only filled stars
                                                />
                                            ))}
                                        </div> */}
                                        <div className='vertical_line'></div>
                                        <div className="emoji-icon">
                                            <img src={getEmojiImage(companyDataSelect?.company?.totalReviewsAvg)} alt="emoji" />
                                        </div>
                                    </div>
                                    {companyDataSelect?.company?.totalReviews !== 0 && <p>Based on {companyDataSelect?.company?.totalReviews} reviews, {getRatingText(companyDataSelect?.company?.totalReviewsAvg)}.</p>}
                                </div>
                            </div>
                            <Grid container sx={{ mb: 1 }} className="align-items-end">
                                <Grid item xs={6}>
                                    <div className="title-decor">Reviews</div>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ float: "right" }}>
                                        <label className='select-label-up'>Sort by:</label>
                                        <Select
                                            value={sortby.trim()}
                                            onChange={handleChangeSort}
                                            input={<SmallSelect />}
                                            IconComponent={IoIosArrowDown}
                                        >
                                            <MenuItem value={"latest"}>Latest</MenuItem>
                                            <MenuItem value={"oldest"}>Oldest</MenuItem>
                                            <MenuItem value={"highest"}>Highest Rating</MenuItem>
                                            <MenuItem value={"lowest"}>Lowest Rating</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container>
                                {/* Generated list */}
                                <ReviewList sort={sortby} setShareModal={setShareModal} getEmojiImage={getEmojiImage} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className='section-review-star-map'>
                                <div style={
                                    { width: "100%" }
                                }>

                                    {companyData?.name ?
                                        (companyData?.latitude ? <div dangerouslySetInnerHTML={{
                                            __html: ` <iframe  width="100%" height="200" 
                                    frameborder="0" 
                                    scrolling="no" 
                                    marginheight="0" 
                                    marginwidth="0" 
                                    src="https://maps.google.com/maps?q=${companyData.latitude + ',' + companyData.longitude}&hl=eng&z=14&amp;output=embed"
                                    >
                                    </iframe>
                                    
                                    ` }} >
                                        </div> : <></>)
                                        : <>
                                            <Skeleton sx={{ height: "200px" }} />
                                        </>}

                                </div>
                                {companyData?.name && <a href={companyData?.website} className="visit-web">Visit Website</a>}

                                <h4 className='title'>Information</h4>
                                <div className="address-icon-bef">
                                    <div className='wrapper'>
                                        <div>
                                            <div className='head-text-info'>Address</div>
                                            <div className='body-text-info'>{companyData?.name ? (companyData?.location || "None") : <Skeleton />}</div>
                                        </div>
                                        <div>
                                            <img src="/admin-images/location-ic.svg" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className='hours-icon-bef'>
                                    <div className="wrapper">
                                        <div>
                                            <div className='head-text-info'>Hours of Operation</div>
                                            {companyData?.working_hours?.from ?
                                                <div className='body-text-info'>From {companyData.working_hours.from} to {companyData.working_hours.to}</div>
                                                : (companyData?.name ? <div className='body-text-info'>None</div> : <Skeleton />)}
                                        </div>
                                        <div>
                                            <img src="/admin-images/clock-ic.svg" alt="icon" />
                                        </div>
                                    </div>
                                </div>
                                {companyDataSelect?.company?.categories?.length > 0 &&
                                    <div className='categories-icon-bef'>
                                        <div className="wrapper">
                                            <div>
                                                <div className="head-text-info">Categories</div>
                                                <div className='body-text-info'>{companyDataSelect?.company?.categories?.map((item, index) => {
                                                    return <Chip sx={{ mb: 1, mr: 1 }} onClick={() => { navigation('/categories/' + item.slug) }} key={index} label={item.name} />
                                                })}
                                                </div>
                                            </div>
                                            <div>
                                                <img src="/admin-images/categories-ic.svg" alt="icon" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* <Container maxWidth="xl">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img height="40px" src={logoiReview} />

                        <span className="a-nodecorate">
                            <a className="a-footer" href="#">Terms and Conditions</a>
                            <a className="a-footer" href="#">Privacy</a>
                            <a className="a-footer" href="#">{new Date().getFullYear()} iReview</a>
                        </span>
                    </Box>
                </Container> */}
            </div>

        </>
    )
}

export default About