import axios from "axios";
import { getLocalUser } from "../../components/TokenControl/parts/useLocalUser";
import { baseUrlDev } from "../../components/Helpers/baseUrl";


let controllerReviews = new AbortController();
let controllerBoxes = new AbortController();
let controllerLocations = new AbortController();
let controllerLocationsTa = new AbortController();
let controllerTopNav = new AbortController();
let controllerDashboard = new AbortController();
let controllerReputation = new AbortController();
let controllerAnalysis = new AbortController();
let controllerReplies = new AbortController();
let controllerAutopilotAi = new AbortController();
let controllerUser = new AbortController();
let controllerPageVisits = new AbortController();
let controllerSubscriptionValues = new AbortController();
let controllerPermissions = new AbortController();
let controllerNotificationLoadMore = new AbortController();
let googleConnectionsController = new AbortController();
let facebookConnectionsController = new AbortController();
let yelpConnectionsController = new AbortController();
let sourceConnectionsController = new AbortController();
let tripAdvisorConnectionsController = new AbortController();
let controllerDashboardVisits = new AbortController();
let controllerDashboardCharts = new AbortController();
let controllerPreUploadedImages = new AbortController();
let controllerLogs = new AbortController()
let controllerTrendingReviews = new AbortController();
let controllerTrendingRatings = new AbortController();
let controllerSocialPosts = new AbortController();
let controllerSocialStats = new AbortController()
let controllerAchievements = new AbortController();
let controllerDashboardProgressInfo = new AbortController()


export const handle404Error = () => ({
    type: 'HANDLE_404_ERROR'
});

export const handle404ErrorReset = () => ({
    type: 'HANDLE_404_ERROR_RESET'
});

function getRequest(url, callback, dispatch, onErrorCallback) {
    axios.get(`${baseUrlDev}${url}`)
        .then(res => res.data)
        .then(data => dispatch(callback(data)))
        .catch((err) => {
            if (onErrorCallback) {
                onErrorCallback?.(err);
            } else {
                console.log('err', err);
            }
            if (err.response?.status === 401) {
                window.location.href = `${window.location.origin}/login`
            }
            if (err.response?.status === 423) {
                window.location.href = `${window.location.origin}/locked`
            }
        });
}

function getRequestProtected(url, callback, dispatch, returnEmpty = false, onErrorCallback = null, cName = '') {
    axios.get(`${baseUrlDev}${url}`, { signal: cName && cName.signal, headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(res => res.data)
        .then(data => dispatch(callback(data)))
        .catch((err) => {
            if (err.response?.status === 423) {
                window.location.href = `${window.location.origin}/locked`
            }
            if (returnEmpty && err.response?.status === 404) {
                dispatch(callback([]))
            }
            if (err.response?.status === 401) {
                window.location.href = `${window.location.origin}/login`
            } else if (err.response?.status === 404) {
                dispatch(handle404Error())
            }
            if (onErrorCallback) {
                dispatch(onErrorCallback(err.response.status))
            } else {
                console.log('err', err);
            }
        });
}
function getRequestPostProtected(url, body, callback, dispatch, cName, callbackLoader) {
    axios.post(`${baseUrlDev}${url}`, { ...body }, { signal: cName && cName.signal, headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(res => res.data)
        .then(data => {
            dispatch(callback(data))
            if(callbackLoader){
                dispatch(callbackLoader(false))
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                window.location.href = `${window.location.origin}/login`
            }
            if (err.response?.status === 423) {
                window.location.href = `${window.location.origin}/locked`
            }
            else if (err.response?.status === 404) {
                dispatch(handle404Error())
            }
        });
}

function getRequestParamProtected(url, param, callback, dispatch, cName, setLoading) {
    axios.get(`${baseUrlDev}${url}`, {
        params: {
            ...param
        },
        signal: cName && cName.signal,
        headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' },
    }).then(res => res.data)
        .then(data => dispatch(callback(data)))
        .catch((err) => {
            if (err.response?.status === 401) {
                window.location.href = `${window.location.origin}/login`
            }
            if (err.response?.status === 423) {
                window.location.href = `${window.location.origin}/locked`
            }
        })
}

export const openVideoModal = () => {
    return {
        type: 'OPEN_VIDEO_MODAL'
    }
}
export const closeVideoModal = () => {
    return {
        type: 'CLOSE_VIDEO_MODAL'
    }
}

export const loadingTables = () => {
    return {
        type: "LOADING_TABLES",
    }
}
export const clearLoadingTables = () => {
    return {
        type: "CLEAR_LOADING_TABLES",
    }
}
export const loadingMore = () => {
    return {
        type: "LOADING_MORE"
    }
}


export const openExportedAlert = (open, message) => {
    return{
        type: 'OPEN_EXPORTED_ALERT',
        payload: {
            open,
            message
        }
    }
}

export const checkImpersonate = (show) => {
    return {
        type: "CHECK_IMPERSONATE",
        payload: show
    }
}

export const checkDemo = (show) => {
    return {
        type: "CHECK_DEMO",
        payload: show
    }
}
export const checkCancel = (show) => {
    return {
        type: "CHECK_CANCEL",
        payload: show
    }
}
export const getPermissions = (data) => {
    return {
        type: "GET_PERMISSIONS",
        payload: data
    }
}
export const fetchPermissions = (location, group_id) => {
    let loc = location !== "all" ? location : ""
    let group = group_id
    return (dispatch) => {
        controllerPermissions.abort();
        controllerPermissions = new AbortController()
        getRequestParamProtected(`get-permissions`, { location: loc, group_id: group }, getPermissions, dispatch, controllerPermissions)
    }
}
export const clearFetchPermissions = () => {
    return {
        type: "CLEAR_PERMISSIONS"
    }
}
//loc selected
// get loc selected
export const getLocationSelected = (id) => {
    return {
        type: "GET_LOCATION_SELECTED",
        payload: id
    }
}
// end of loc selected
// get group selected
export const getGroupSelected = (id) => {
    return {
        type: "GET_GROUP_SELECTED",
        payload: id
    }
}
// end of group selected
// get group selected
export const getGroupLocation = (id) => {
    return {
        type: "GET_GROUP_LOCATION_SELECTED",
        payload: id
    }
}
// end of group selected
//User
// get user
export const getUserInfo = (data) => {
    return {
        type: "GET_USER_INFO",
        payload: data
    }
}

export const fetchUserInfo = () => {
    return (dispatch) => {
        controllerUser.abort();
        controllerUser = new AbortController()
        getRequestParamProtected('me', {}, getUserInfo, dispatch, controllerUser)
    }
}
// end of get user

//TAGS
// get tags
export const getLocationTags = (data) => {
    return {
        type: "GET_LOCATION_TAGS",
        payload: data
    }
}

export const fetchLocationTags = (id) => {
    return (dispatch) => {
        dispatch(loadingTables());
        getRequestProtected(`locations/${id}/tags`, getLocationTags, dispatch)
    }
}
export const clearTags = () => {
    return {
        type: "CLEAR_TAGS"
    }
}
// end of get tags

//Sources
// get sources
export const getLocationSources = (data) => {
    return {
        type: "GET_LOCATION_SOURCES",
        payload: data
    }
}

export const fetchLocationSources = (id) => {
    return (dispatch) => {
        dispatch(loadingTables());
        getRequestProtected(`locations/${id}/sources`, getLocationSources, dispatch)
    }
}
export const getLocAvaliableSources = (data) => {
    return {
        type: "GET_LOCATION_AVALIABLE_SOURCES",
        payload: data
    }
}
export const fetchLocAvaliableSources = () => {
    return (dispatch) => {
        getRequestProtected(`locations/sources`, getLocAvaliableSources, dispatch)
    }
}
export const clearSources = () => {
    return{
        type: "CLEAR_SOURCES"
    }
}
// end of get sources

//Reviews
// get reviews
export const getReviews = (data) => {
    return {
        type: "GET_REVIEWS",
        payload: data
    }
}
export const setReviews = (data) => {
    return {
        type: "SET_REVIEWS",
        payload: data
    }
}
export const fetchReviews = (location, keyword, sources, status, rating, from, to, page, tagsFilter, order, group, append = false) => {
    let fromDate = from && from
    let toDate = to && to
    let loc = location !== "all" ? location : ""
    let group_id = group
    return (dispatch) => {
        dispatch(loadingTables());
        controllerReviews.abort();
        controllerReviews = new AbortController()
        getRequestPostProtected(`reviews`, {group_id, location: loc, keyword, sources, status, page, rating, 'date-from': fromDate, 'date-to': toDate, tags: tagsFilter && tagsFilter.length > 0 ? tagsFilter : "", order }, append ? getReviews : setReviews, dispatch, controllerReviews)
    }
}
export const getFormatted = (data) => {
    return {
        type: "FORMATTED_REVIEWS",
        payload: data
    }
}
export const clearFormatted = () => {
    return {
        type: "CLEAR_FORMATTED_REVIEWS",
    }
}
export const togglePinStat = (id, pin) => {
    return {
        type: "TOGGLE_PINNED_STATUS",
        payload: {id, pin}
    }
}
export const repliedReview = (id, status) => {
    return {
        type: "REPLIED_STATUS",
        payload: {rev_id: id, status}
    }
}
export const clearReviews = () => {
    return {
        type: "CLEAR_REVIEWS",
    }
}
export const memorizeLastReview = (id) => {
    return{
        type: "MEMORIZE_LAST_REVIEW_EDITED",
        payload: id
    }
}
// end of get reviews
// get reviews Coupon
export const getReviewsCoupon = (data) => {
    return {
        type: "GET_REVIEWS_COUPON",
        payload: data
    }
}
export const fetchReviewsCoupon = (location) => {
    let loc = location !== "all" ? location : ""
    return (dispatch) => {
        getRequestParamProtected(`reviews/coupon`, { location: loc }, getReviewsCoupon, dispatch)
    }
}
export const clearReviewsCoupon = () => {
    return {
        type: "CLEAR_REVIEWS_COUPON",
    }
}
// end of get reviews

// get review
export const getReview = (data) => {
    return {
        type: "GET_REVIEW",
        payload: data
    }
}
export const clearReview = () => {
    return {
        type: "CLEAR_REVIEW"
    }
}
export const fetchReview = (id, group, location, keyword, sourcesFilter, status, rating, order, tags, from, to, page) => {
    return (dispatch) => {
        dispatch(clearReview())
        let loc = location !== "all" ? location : ""
        getRequestPostProtected(`reviews/${id}/details`, {location: loc, group_id: group, keyword, sources: sourcesFilter, status, rating, order, tags, "date-from": from, "date-to":to, page:page}, getReview, dispatch)
    }
}
// end of get review
export const getGeneratedCoupons = (data) => {
    return{
        type: "GET_GENERATED_COUPONS",
        payload: data
    }
}

export const fetchGeneratedCoupons = () => {
    return(dispatch) => {
        dispatch(loadingTables());
        getRequestProtected(`coupon`, getGeneratedCoupons, dispatch)
    }
}

export const clearGeneratedCoupons = (data) => {
    return {
        type: "CLEAR_GENERATED_COUPONS"
    }
}
export const addNewCoupon = (data) => {
    return{
        type: "ADD_GENERATED_COUPON",
        payload: data
    }
}
export const editNewCoupon = (data) => {
    return{
        type: "EDIT_GENERATED_COUPON",
        payload: data
    }
}
export const assignCoupons = (data) => {
    return{
        type: "ASSIGN_COUPONS",
        payload: data
    }
}
export const deleteGeneratedCoupon = (id) => {
    return{
        type: "DELETE_GENERATED_COUPON",
        payload: id
    }
}
//Get review notes
export const getReviewNotes = (data) => {
    return {
        type: "GET_REVIEW_NOTES",
        payload: data
    }
}
export const fetchReviewNotes = (id) => {
    return (dispatch) => {
        getRequestProtected(`reviews/${id}/notes`, getReviewNotes, dispatch)
    }
}
export const addNotes = (data) => {
    return {
        type: "ADD_NOTES",
        payload: data
    }
}
//End of Get review notes
//Review-Filter
export const clearFilters = (data) => {
    return {
        type: 'CLEAR_FILTER',
        payload: data
    }
}
export const clearFilterStat = () => {
    return{
        type: "CLEAR_FILTER_STATUS"
    }
}
export const searchReviewKeyword = (value) => {
    return {
        type: "SEARCH_REVIEW_KEYWORD",
        payload: value
    }
}
export const orderReviews = (value) => {
    return {
        type: "ORDER_REVIEWS",
        payload: value
    }
}
export const paginationReviews = (value) => {
    return {
        type: "PAGINATION_REVIEWS",
        payload: value
    }
}
export const reviewSourcesFilter = (value) => {
    return {
        type: "REVIEW_SOURCES_FILTER",
        payload: value
    }
}
export const reviewRatingsFilter = (value) => {
    return {
        type: "REVIEW_RATINGS_FILTER",
        payload: value
    }
}
export const reviewStatusFilter = (value) => {
    return {
        type: "REVIEW_STATUS_FILTER",
        payload: value
    }
}
export const reviewRangeFilter = (value) => {
    return {
        type: "REVIEW_RANGE_FILTER",
        payload: value
    }
}
export const reviewTagsFilter = (value) => {
    return {
        type: "REVIEW_TAGS_FILTER",
        payload: value
    }
}
//End of filters

//GET Locations and Sources
export const allLocationSources = (data) => {
    return {
        type: 'GET_LOCATIONS_SOURCES',
        payload: data
    }
}

export const fetchAllLocationSources = (id, group) => {
    return (dispatch) => {
        getRequestPostProtected(`reviews/tools`, { location: id, group_id: group }, allLocationSources, dispatch)
    }
}

//Get reviews top boxes
export const reviewsTopBoxes = (data) => {
    return {
        type: 'GET_REVIEWS_TOP_BOXES',
        payload: data
    }
}

export const fetchReviewsTopBoxes = (location, keyword, sources, status, rating, from, to,  tagsFilter, order, group) => {
    let fromDate = from && from
    let toDate = to && to
    let loc = location !== "all" ? location : ""
    let group_id = group
    return (dispatch) => {
        controllerBoxes.abort();
        controllerBoxes = new AbortController()
        getRequestPostProtected(`reviews/stats`, {group_id, location: loc, keyword, sources, status, rating, 'date-from': fromDate, 'date-to': toDate, tags: tagsFilter, order }, reviewsTopBoxes, dispatch, controllerBoxes)
    }
}
export const clearTopBoxes = () => {
    return {
        type: "CLEAR_TOP_BOXES",
        payload: undefined
    }
}
//Get reviews history top boxes
export const reviewsHistory = (data) => {
    return {
        type: 'GET_REVIEWS_HISTORY',
        payload: data
    }
}

export const fetchReviewsHistory = (id) => {
    return (dispatch) => {
        controllerLogs.abort();
        controllerLogs = new AbortController()
        getRequestProtected(`reviews/${id}/logs`, reviewsHistory, dispatch, false, undefined, controllerLogs)
    }
}
export const reviewHistoryLoading = () => {
    return {
        type: "LOADING_HISTORY",
    }
}

//Get notifications
export const getNotificationsFilter = (id) => {
    return {
        type: "GET_NOTIFICATIONS_FILTER",
        payload: id
    }
}
let inQueue = false;
let inQueueUnread = false;
export const getNotifications = (data) => {
    return {
        type: "GET_NOTIFICATIONS",
        payload: data
    }
}

export const getNotificationsLoadMore = (data) => {
    inQueue = false
    return {
        type: "GET_NOTIFICATIONS_LOAD_MORE",
        payload: data
    };
};

export const fetchNotifications = (page, loadMore = false, setCounter) => {
    return (dispatch) => {
        if(loadMore){
            if(inQueue){
                return;
            }
            setCounter()
            inQueue = true;
        }
        const actionCreator = loadMore ? getNotificationsLoadMore : getNotifications;
        getRequestProtected(`notifications?page=${page}`, actionCreator, dispatch);
    };
};
export const readLoadedNotifications = (id) => {
    return{
         type: "READ_LOADED_NOTIFICATIONS",
         payload: id,
    }
 }
export const notificationPagination = (page) => {
    return {
        type: "NOTIFICATION_PAGINATION",
        payload: page
    };
}
export const notificationUnreadPagination = (page) => {
    return {
        type: "UNREAD_NOTIFICATION_PAGINATION",
        payload: page
    };
}

export const getNotificationsUnread = (data) => {
    return {
        type: "GET_NOTIFICATIONS_UNREAD",
        payload: data
    }
}
export const getNotificationsUnreadLoadMore = (data) => {
    inQueueUnread = false
    return {
        type: "GET_NOTIFICATIONS_UNREAD_LOAD_MORE",
        payload: data
    };
};

export const fetchNotificationsUnread = (page, loadMore = false, setCounter) => {
    return (dispatch) => {
        if(loadMore){
            if(inQueueUnread){
                return;
            }
            setCounter()
            inQueueUnread = true;
        }
        const actionCreator = loadMore ? getNotificationsUnreadLoadMore : getNotificationsUnread;
        getRequestProtected(`notifications?unread=true&page=${page}`, actionCreator, dispatch);
    };
};


export const readAllNotifications = () => {
    return {
        type: "READ_ALL_NOTIFICATIONS"
    }
}


//Get notifications count
export const getNotificationsCount = (data) => {
    return {
        type: "GET_NOTIFICATIONS_COUNT",
        payload: data
    }
}

export const fetchNotificationsCount = () => {
    return (dispatch) => {
        getRequestProtected(`notifications/count`, getNotificationsCount, dispatch)
    }
}
//Get Quick Replies
export const getQuickReply = (data) => {
    return {
        type: "GET_QUICK_REPLY",
        payload: data
    }

}

export const fetchQuickReply = (type, sorting, search, limit, location_id) => {
    return (dispatch) => {
        controllerReplies.abort();
        controllerReplies = new AbortController()
        getRequestParamProtected(`reviews/quick-replies`, {type, sorting, search, limit, location: location_id}, getQuickReply, dispatch, controllerReplies)
    }
}
//Get Quick Reply
export const getQuickReplyDetails = (data) => {
    return {
        type: "GET_QUICK_REPLY_DETAILS",
        payload: data
    }

}
export const fetchQuickReplyDetails = (id) => {
    return (dispatch) => {
        getRequestProtected(`reviews/quick-replies/${id}/show`, getQuickReplyDetails, dispatch)
    }
}
export const clearQuickReplyDetails = () => {
    return{
        type: "CLEAR_QUICK_REPLY_DETAILS"
    }
}
//Get Page Visits
export const getPageVisits = (data) => {
    return{
        type: "GET_PAGE_VISITS",
        payload: data
    }
}
export const clearPageVisits = () => {
    return{
        type: "CLEAR_PAGE_VISITS",
    }
}
export const fetchPageVisits = (location, from, to, group) => {
    let loc = location !== "all" ? location : ""
    let group_id = group
    return (dispatch) => {
        controllerPageVisits.abort();
        controllerPageVisits = new AbortController()
        getRequestPostProtected(`page-visits`, {group_id, location: loc, from, to}, getPageVisits, dispatch, controllerPageVisits)
    }
}
//End of Page Visits
//Navbar video modal
export const showYoutubeContent = (path) => {
    return {
        type: "SHOW_YOUTUBE_CONTENT",
        payload: path
    }
}

//Navbar Search
export const topNavSearch = (data) => {
    return {
        type: "SEARCH_TOP_NAV",
        payload: data
    }
}

export const fetchTopNavData = (value) => {
    return (dispatch) => {
        controllerTopNav.abort();
        controllerTopNav = new AbortController()
        getRequestPostProtected(`search`, { q: value }, topNavSearch, dispatch, controllerTopNav)
    }
}

export const clearTopNav = () => {
    return {
        type: "CLEAR_TOP_NAVBAR"
    }
}

//Get New Loactions
export const getNewLocations = (data) => {
    return {
        type: "GET_NEW_LOCATION",
        payload: data
    }
}

export const fetchNewLocations = (value) => {
    return (dispatch) => {
        controllerLocations.abort();
        controllerLocations = new AbortController()
        value.length > 2 ? getRequestPostProtected(`locations/search`, { keyword: value }, getNewLocations, dispatch, controllerLocations) : controllerLocations.abort();
    }
}
export const clearNewLocations = () => {
    return {
        type: "CLEAR_NEW_LOCATIONS"
    }
}

//Get New Loactions for TA
export const getLocationTa = (data) => {
    return {
        type: "GET_LOCATION_TRIPADVISOR",
        payload: data
    }
}

export const fetchLocationsTa = (value) => {
    return (dispatch) => {
        controllerLocationsTa.abort();
        controllerLocationsTa = new AbortController()
        value.length >= 5 ? getRequestPostProtected(`locations/search-location`, { keyword: value }, getLocationTa, dispatch, controllerLocationsTa) : controllerLocationsTa.abort();
    }
}
export const clearLocationTa = () => {
    return {
        type: "CLEAR_LOCATION_TRIPADVISOR"
    }
}

//Get Dashboard
export const getDashboardStats = (data) => {
    return {
        type: "GET_DASHBOARD_STATS",
        payload: data
    }
}

export const loadingDashboard = (data) => {
    return {
        type: "LOADING_DASHBOARD",
        payload: data
    }
}

export const fetchDashboardStats = (from, to, location_id,  group, refresh_cache) => {
    let data = location_id && location_id !== "all" ? { from, to, location: location_id, group_id: group, "refresh_cache": refresh_cache ? refresh_cache : false } : { from, to, group_id: group, "refresh_cache": refresh_cache ? refresh_cache : false }
    return (dispatch) => {
        dispatch(loadingDashboard(true));
        controllerDashboard.abort();
        controllerDashboard = new AbortController()
        getRequestPostProtected('dashboard/web', data, getDashboardStats, dispatch, controllerDashboard, loadingDashboard) 
    }
}

export const clearDashboardStats = () => {
    return {
        type: "CLEAR_DASHBOARD_STATS",
    }
}
//End of get dashboard
//Get Dashboard Charts
export const getDashboardCharts = (data) => {
    return{
        type: 'GET_DASHBOARD_CHARTS',
        payload: data
    }
}
export const fetchDashboardCharts = (from, to, location_id,  group, refresh_cache) => {
    let data = location_id && location_id !== "all" ? { from, to, location: location_id, group_id: group} : { from, to, group_id: group }
    return (dispatch) => {
        controllerDashboardCharts.abort();
        controllerDashboardCharts = new AbortController()
        getRequestPostProtected('dashboard/average-rating-charts', data, getDashboardCharts, dispatch, controllerDashboardCharts)
    }
}
export const clearDashboardCharts = () => {
    return {
        type: "CLEAR_DASHBOARD_CHARTS",
    }
}
//End of Get Dashboard Charts
//Get Dashboard First Endpoint
export const getDashboardStatsVisits = (data) => {
    return {
        type: "GET_DASHBOARD_STATS_VISITS",
        payload: data
    }
}

export const getDashboardProgressInfo = (data) => {
    return {
        type: "GET_DASHBOARD_PROGRESS_INFO",
        payload: {...data, completed: true}
    }
}

export const fetchDashboardVisits = (from, to, location_id,  group) => {
    let data = location_id && location_id !== "all" ? { from, to, location: location_id, group_id: group } : { from, to, group_id: group }
    return (dispatch) => {
        controllerDashboardVisits.abort();
        controllerDashboardVisits = new AbortController()
        getRequestPostProtected('dashboard/links-statistics', data, getDashboardStatsVisits, dispatch, controllerDashboardVisits)
    }
}

export const fetchDashboardProgressInfo = (location_id, time_range, rating_to_reach, sourceName) => {
    let data = { location: location_id !== "all" ? location_id : "", time_range: time_range, rating_to_reach, sourceName }
    return (dispatch) => {
        dispatch(loadingDashboardProgressInfo())
        controllerDashboardProgressInfo.abort();
        controllerDashboardProgressInfo = new AbortController()
        getRequestPostProtected('dashboard/additional-reviews-needed', data, getDashboardProgressInfo, dispatch, controllerDashboardProgressInfo)
    }
}

export const clearDashboardVisits = () => {
    return {
        type: "CLEAR_DASHBOARD_VISITS",
    }
}
export const loadingDashboardProgressInfo = () => {
    return {
        type: "LOADING_DASHBOARD_PROGRESS_INFO",
    }
}
export const clearDashboardProgressInfo = () => {
    return {
        type: "CLEAR_DASHBOARD_PROGRESS_INFO",
    }
}
//End of get dashboard First Endpoint
//Get Dashboard
export const getDashboardReviewCollection = (data) => {
    return {
        type: "GET_DASHBOARD_REVIEW_COLLECTION",
        payload: data
    }
}

export const fetchDashboardReviewCollection = (location_id, group) => {
    let loc = location_id && location_id !== "all" ? location_id : ""
    return (dispatch) => {
        getRequestParamProtected(`dashboard/target-reviews`, {location: loc, group_id: group}, getDashboardReviewCollection, dispatch)
    }
}

export const clearDashboardReviewCollection = () => {
    return {
        type: "CLEAR_DASHBOARD_REVIEW_COLLECTION",
    }
}
//End of get dashboard
//Get Dashboard
export const getDashboardAutopilot = (data) => {
    return {
        type: "GET_DASHBOARD_AUTOPILOT",
        payload: data
    }
}

export const fetchDashboardAutopilot = (location_id,  group) => {
    let data = location_id && location_id !== "all" ? {location: location_id } : {group_id: group }
    return (dispatch) => {
        controllerAutopilotAi.abort();
        controllerAutopilotAi = new AbortController()
        getRequestPostProtected('dashboard/getStatistics', data, getDashboardAutopilot, dispatch, controllerAutopilotAi)
    }
}

export const clearDashboardAutopilot = () => {
    return {
        type: "CLEAR_DASHBOARD_AUTOPILOT",
    }
}
//Review Page get Items
//Get AutoPilot Settings
export const getAutopilotSettings = (data) => {
    return{
        type: 'GET_AUTOPILOT_SETTINGS',
        payload: data
    }
}
export const fetchAutopilotSettings = (location_id) => {
    return (dispatch) => {
        getRequestProtected(`locations/show-ai-settings/${location_id}`, getAutopilotSettings, dispatch)
    }
}
export const clearAutopilotSettings = () => {
    return{
        type: 'CLEAR_AUTOPILOT_SETTINGS'
    }
}
//End of autopilot settings

export const getReviewPage = (data) => {
    return {
        type: "GET_REVIEW_PAGE",
        payload: data
    }
}

export const clearReviewPage = () => {
    return {
        type: "CLEAR_REVIEW_PAGE",
    }
}

export const fetchReviewPage = (id) => {
    return (dispatch) => {
        getRequestProtected(`locations/reviewpage/${id}`, getReviewPage, dispatch, true)
    }
}

//Notification Settings 
export const getNotificationSettings = (data) => {
    return {
        type: "GET_NOTIFICATION_SETTINGS",
        payload: data
    }
}

export const fetchNotificationSettings = (location_id) => {
    return (dispatch) => {
        getRequestPostProtected('notifications/settings', { location: location_id }, getNotificationSettings, dispatch)

    }
}

export const clearNotificationAccount = () => {
    return {
        type: "CLEAR_ACCOUNT_NOTIFICATION",
    }
}


//Locations Reputations
export const getReputation = (data) => {
    return {
        type: 'GET_REPUTATION',
        payload: data
    }
}

export const clearReputationPage = () => {
    return {
        type: "CLEAR_REPUTATION",
    }
}

export const fetchReputation = (from, to, location, group) => {
    return (dispatch) => {
        controllerReputation.abort();
        controllerReputation = new AbortController()
        getRequestParamProtected('locations/reputations', { from, to, location, group_id: group }, getReputation, dispatch, controllerReputation)
    }
}

//Locations Information
export const getLocationsInformation = (data) => {
    return {
        type: "GET_LOCATIONS_INFORMATION",
        payload: data
    }
}
export const fetchLocationsInformation = () => {
    return (dispatch) => {
        getRequestProtected('locations/settings', getLocationsInformation, dispatch)
    }
}

export const clearLocationsInformation = () => {
    return {
        type: "CLEAR_LOCATIONS_INFORMATION",
    }
}
//Location Information
export const getLocationInformation = (data) => {
    return {
        type: "GET_LOCATION_INFORMATION",
        payload: data
    }
}
export const fetchLocationInformation = (id) => {
    return (dispatch) => {
        getRequestProtected(`locations/settings/${id}`, getLocationInformation, dispatch)
    }
}

export const clearLocationInformation = () => {
    return {
        type: "CLEAR_LOCATION_INFORMATION",
    }
}
//Lisitng
//Business Categories
export const getBusinessCategories = (data) => {
    return {
        type: "GET_BUSINESS_CATEGORIES",
        payload: data
    }
}
export const fetchBusinessCategories = (id) => {
    return (dispatch) => {
        getRequestProtected(`business/category`, getBusinessCategories, dispatch)
    }
}

export const clearBusinessCategories = () => {
    return {
        type: "CLEAR_BUSINESS_CATEGORIES",
    }
}
//End of Business Categories
export const getLocationListing = (data) => {
    return {
        type: 'GET_LOCATION_LISTING',
        payload: data
    }
}
export const fetchLocationListing = (page, location, group) => {
    return (dispatch) => {
        getRequestParamProtected('locations/listing-score', { page, location: location == "all" ? "" : location, group_id: group }, getLocationListing, dispatch)
    }
}
export const clearLocationListing = () => {
    return {
        type: "CLEAR_LOCATION_LISTING",
    }
}
export const getLocationList = (data) => {
    return {
        type: 'GET_LOCATION_LIST',
        payload: data
    }
}
export const fetchLocationList = (id) => {
    return (dispatch) => {
        getRequestProtected(`locations/listing-score/${id}`, getLocationList, dispatch)
    }
}
export const clearLocationList = () => {
    return {
        type: "CLEAR_LOCATION_LIST",
    }
}
//Users Management
export const getUsersManagement = (data) => {
    return {
        type: "GET_USERS_MANAGEMENT",
        payload: data
    }
}

export const fethUsersManagement = (location) => {
    let location_id = location !== "all" ? location : ""
    return (dispatch) => {
        getRequestPostProtected('users', { location: location_id }, getUsersManagement, dispatch)
    }
}

export const clearUsesrManagement = () => {
    return {
        type: "CLEAR_USERS_MANAGEMENT",
    }
}

//Show User Management
export const getUserManagement = (data) => {
    return {
        type: "GET_USER_MANAGEMENT",
        payload: data
    }
}

export const fetchUserManagement = (id) => {
    return (dispatch) => {
        getRequestProtected(`users/${id}`, getUserManagement, dispatch)
    }
}

export const clearUserManagement = () => {
    return {
        type: "CLEAR_USER_MANAGEMENT",
    }
}

//get Analysis
const loadingPredictReviews = () => {
    return{
        type: 'LOADING_PREDICT_REVIEWS'
    }
}
const errorPredictReviews = (err) => {
    return{
        type: 'ERROR_PREDICT_REVIEWS',
        payload: err
    }
}
const loadingPredictRating = () => {
    return{
        type: 'LOADING_PREDICT_RATING'
    }
}
const errorPredictRatings = () => {
    return{
        type: 'ERROR_PREDICT_RATINGS'
    }
}
export const getReviewsPredict = (data) => {
    return{
        type: "GET_REVIEWS_PREDICT",
        payload: data
    }
}
export const fetchReviewsPredict = (source, time, location) => {
    return (dispatch) => {
        dispatch(loadingPredictReviews())
        let query = `predict/review-trend?filter=${time}`
        if (location && location !== "all") {
            query += `&location=${location}`;
        }
        if (source && source !== "all") {
            query += `&source=${source}`;
        }
        controllerTrendingReviews.abort();
        controllerTrendingReviews = new AbortController()
        getRequestProtected(query, getReviewsPredict, dispatch, false, errorPredictReviews, controllerTrendingReviews)
    }
}
export const getRatingsPredict = (data) => {
    return{
        type: "GET_RATINGS_PREDICT",
        payload: data
    }
}
export const fetchRatingsPredict = (source, time, location) => {
    return (dispatch) => {
        dispatch(loadingPredictRating())
        let query = `predict/rating-trend?filter=${time}`
        if (location && location !== "all") {
            query += `&location=${location}`;
        }
        if (source && source !== "all") {
            query += `&source=${source}`;
        }
        controllerTrendingRatings.abort();
        controllerTrendingRatings = new AbortController()
        getRequestProtected(query, getRatingsPredict, dispatch, false, errorPredictRatings, controllerTrendingRatings)
    }
}
// GET REVIEWS 12 MONTHS STATUS //
export const getReviews12Status = (data) => {
    return{
        type: "GET_REVIEWS_12_STATUS",
        payload: data
    }
}
export const getReviews6Status = (data) => {
    return{
        type: "GET_REVIEWS_6_STATUS",
        payload: data
    }
}
export const getReviews3Status = (data) => {
    return{
        type: "GET_REVIEWS_3_STATUS",
        payload: data
    }
}
export const getReviews1Status = (data) => {
    return{
        type: "GET_REVIEWS_1_STATUS",
        payload: data
    }
}
export const fetchReviewsStatus = (source, time, location) => {
    return(dispatch) => {
        let query = `predict/review-trend?filter=${time}`
        if (location && location !== "all") {
            query += `&location=${location}`;
        }
        if (source && source !== "all") {
            query += `&source=${source}`;
        }
        let callback = time == 12 ? getReviews12Status : time == 6 ? getReviews6Status : time == 3 ? getReviews3Status : getReviews1Status
        getRequestProtected(query, callback, dispatch)
    }
}
// END OF GET REVIEWS 12 MONTHS //

// GET REVIEWS 12 MONTHS STATUS //
export const getRatings12Status = (data) => {
    return{
        type: "GET_RATINGS_12_STATUS",
        payload: data
    }
}
export const getRatings6Status = (data) => {
    return{
        type: "GET_RATINGS_6_STATUS",
        payload: data
    }
}
export const getRatings3Status = (data) => {
    return{
        type: "GET_RATINGS_3_STATUS",
        payload: data
    }
}
export const getRatings1Status = (data) => {
    return{
        type: "GET_RATINGS_1_STATUS",
        payload: data
    }
}
export const fetchRatingsStatus = (source, time, location) => {
    return(dispatch) => {
        let query = `predict/rating-trend?filter=${time}`
        if (location && location !== "all") {
            query += `&location=${location}`;
        }
        if (source && source !== "all") {
            query += `&source=${source}`;
        }
        let callback = time == 12 ? getRatings12Status : time == 6 ? getRatings6Status : time == 3 ? getRatings3Status : getRatings1Status
        getRequestProtected(query, callback, dispatch)
    }
}
// END OF GET REVIEWS 12 MONTHS //


//Trending Keywords
const errorTrendingKeywords = (err) => {
    return{
        type: 'ERROR_TRENDING_KEYWORDS',
        payload: err
    }
}
export const loadingTrendingKeywords = () => {
    return{
        type: "LOADING_TRENDING_KEYWORDS"
    }
}
export const getTrendingKeywords = (data) => {
    return{
        type: "GET_TRENDING_KEYWORDS",
        payload: data
    }
}
export const fetchTrendingKeywords = (location) => {
   return(dispatch) => {
        dispatch(loadingTrendingKeywords())
        let query = `predict/keyword-trend`
        if (location && location !== "all") {
            query += `?location=${location}`;
        }
        getRequestProtected(query, getTrendingKeywords, dispatch, false, errorTrendingKeywords)
   }
}
//End of Trending Keywords
//Trending Phrases
const errorTrendingPhrases = (err) => {
    return{
        type: 'ERROR_TRENDING_PHRASES',
        payload: err
    }
}
export const loadingTrendingPhrases = () => {
    return{
        type: "LOADING_TRENDING_PHRASES",
    }
}
export const getTrendingPhrases = (data) => {
    return{
        type: "GET_TRENDING_PHRASES",
        payload: data
    }
}
export const fetchTrendingPhrases = (location) => {
    return(dispatch) => {
        dispatch(loadingTrendingPhrases())
        let query = `predict/phrase-trend`
        if (location && location !== "all") {
            query += `?location=${location}`;
        }
        getRequestProtected(query, getTrendingPhrases, dispatch,  false, errorTrendingPhrases)
    }
}
//End of Trending Phrases
//Analysis dashboard report
export const getAnalysisDashboard = (data) => {
    return{
        type: "GET_ANALYSIS_DASHBOARD",
        payload: data
    }
}
export const fetchAnalysisDashboard = (location) => {
    return(dispatch) => {
        getRequestProtected(`predict/rating-dashboard?location=${location}`, getAnalysisDashboard, dispatch)
    }
}
//End of Analysis dashboard report
const loadingAnalysisSuggestions = () => {
    return{
        type: 'LOADING_ANALYSIS_SUGGESTIONS'
    }
}
const errorAnalysisSuggestions = (err) => {
    return{
        type: 'ERROR_ANALYSIS_SUGGESTIONS',
        payload: err
    }
}
export const getAnalysisSuggestions = (data) => {
    return{
        type: "GET_ANALYSIS_SUGGESTIONS",
        payload: data
    }
}
export const fetchAnalysisSuggestions = (location) => {
    return(disptach) => {
        disptach(loadingAnalysisSuggestions())
        getRequestProtected(`predict/suggestion?location=${location}`, getAnalysisSuggestions, disptach, false, errorAnalysisSuggestions)
    }
}
export const getLocationAnalysis = (data) => {
    return {
        type: "GET_ANALYSIS",
        payload: data
    }
}

export const fetchLocationAnalysis = (id, from, to) => {
    return (dispatch) => {
        controllerAnalysis.abort();
        controllerAnalysis = new AbortController()
        getRequestParamProtected(`locations/${id}/analysis`, { from, to }, getLocationAnalysis, dispatch, controllerAnalysis)
    }
}

const errorCategoryKeywords = (err) => {
    return{
        type: 'ERROR_CATEGORY_KEYWORDS',
        payload: err
    }
}

const loadingAnalysisCategoryKeywords = () => {
    return{
        type: 'LOADING_CATEGORY_KEYWORDS'
    }
}

export const getCategoryKeywords = (data) => {
    return{
        type: 'GET_CATEGORY_KEYWORDS',
        payload: data
    }
}

export const fetchCategoryKeywords = (location) => {
    return(dispatch) => {
        dispatch(loadingAnalysisCategoryKeywords())
        getRequestProtected(`predict/services-analysis?location=${location}`,  getCategoryKeywords, dispatch, false, errorCategoryKeywords)
    }
}

export const clearLocationAnalysis = () => {
    return {
        type: 'CLEAR_ANALYSIS',
    }
}
//get keyword details
export const getKeywordDetails = (data) => {
    return {
        type: "GET_KEYWORD_DETAILS",
        payload: data
    }
}
export const fetchKeywordDetails = (keyword, location_id, rating, from, to) => {
    return (disptach) => {
        getRequestParamProtected(`keyword/statistics`, { keyword, rating, location: location_id, from, to }, getKeywordDetails, disptach)
    }
}

export const clearKeywordDetails = () => {
    return {
        type: "CLEAR_KEYWORD_DETAILS"
    }
}
//get added keywords
export const getAddedKeywords = (data) => {
    return {
        type: 'GET_ADDED_KEYWORDS',
        payload: data
    }
}
export const fetchAddedKeywords = (account_id) => {
    return (dispatch) => {
        getRequestParamProtected(`keyword/index`, { account_id }, getAddedKeywords, dispatch)
    }
}

export const clearAddedKeywords = () => {
    return {
        type: "CLEAR_ADDED_KEYWORDS"
    }
}

// Get Gamify Members
export const clearGamifyMembers = () => {
    return {
        type: "CLEAR_GAMIFY",
        payload: undefined
    }
}

export const getGamifyMembers = (data) => {
    return {
        type: 'GET_GAMIFY_MEMBERS',
        payload: data
    }
}
export const fetchGamifyMembers = (location_id) => {
    return (dispatch) => {
        getRequestParamProtected(`locations/get-gamify-members/${location_id}`, { location: location_id }, getGamifyMembers, dispatch)
    }
}

export const getGamifyTarget = (data) => {
    return {
        type: 'GET_GAMIFY_TARGET',
        payload: data
    }
}

export const fetchGamifyTarget = (location_id) => {
    return (dispatch) => {
        getRequestParamProtected(`locations/data-target/${location_id}`, { location: location_id }, getGamifyTarget, dispatch)
    }
}
// Get Gamify Members

//Get Competitors 
export const getCompetitors = (data) => {
    return {
        type: "GET_COMPETITORS",
        payload: data
    }
}

export const fetchCompetitors = (location_id) => {
    return (dispatch) => {
        getRequestProtected(`locations/competitors/${location_id}`, getCompetitors, dispatch)
    }
}

export const clearCompetitors = () => {
    return {
        type: "CLEAR_COMPETITORS"
    }
}

// Revushare web
export const widgetCodeAction = (data) => {
    return {
        type: 'GET_WIDGET_CODE',
        payload: data
    }
}

export const fetchWidgetCode = (id) => {
    return (dispatch) => {
        getRequestProtected(`locations/widget/${id}`, widgetCodeAction, dispatch)
    }
}

export const fetchWidgetCodeLoading = () => {
    return {
        type: "FETCH_WIDGET_LOADING"
    }
}

// Revushare web

// Group Page
export const getGroupPageAction = (data) => {
    return {
        type: 'GET_GROUP_PAGE',
        payload: data
    }
}

export const fetchGroupPage = (id) => {
    return (dispatch) => {
        // getRequestProtected(`locations/${id}/group/page`, getGroupPageAction, dispatch)
        getRequestProtected(`group/${id}/show`, getGroupPageAction, dispatch)
    }
}
export const clearGroupPage = () => {
    return {
        type: "CLEAR_GROUP_PAGE"
    }
}
// Group Page
// Groups
export const getGroupsAction = (data) => {
    return {
        type: 'GET_GROUPS',
        payload: data
    }
}

export const fetchGroups = (id) => {
    return (dispatch) => {
        getRequestProtected(`group`, getGroupsAction, dispatch)
    }
}
export const clearGroups = () => {
    return {
        type: "CLEAR_GROUPS"
    }
}
// Groups
// Group 
export const getGroupAction = (data) => {
    return {
        type: 'GET_GROUP',
        payload: data
    }
}

export const fetchGroup = (id) => {
    return (dispatch) => {
        getRequestProtected(`group/${id}/show`, getGroupAction, dispatch)
    }
}
export const clearGroup = () => {
    return {
        type: "CLEAR_GROUP"
    }
}
// Group 

//Account Invoices
export const getAccountInvocies = (data) => {
    return {
        type: 'GET_ACCOUNT_INVOICES',
        payload: data
    }
}
export const fetchAccountInvoices = () => {
    return (dispatch) => {
        getRequestProtected(`invoice`, getAccountInvocies, dispatch)
    }
}
//End of Account Invoices
//Account Agreements
export const getAccountAgreements = (data) => {
    return {
        type: 'GET_ACCOUNT_AGREEMENTS',
        payload: data
    }
}
export const fetchAccountAgreements = () => {
    return (dispatch) => {
        getRequestProtected(`subscription/list-contracts`, getAccountAgreements, dispatch)
    }
}
//End of Account Invoices

//Location Connections
export const getLocationConnections = (data) => {
    return {
        type: 'GET_LOCATION_CONNECTIONS',
        payload: data
    }
}
export const fetchLocationConnections = (id) => {
    return (dispatch) => {
        dispatch(loadingConnections());
        getRequestProtected(`locations/${id}/connections`, getLocationConnections, dispatch)
    }
}
export const loadingConnections = () => {
    return {
        type: "LOADING_CONNECTIONS"
    }
}
export const clearLocationConnections = () => {
    return {
        type: "CLEAR_LOCATION_CONNECTION"
    }
}
//End of Location Connections

//Location Connections Facebook
export const getConnectionFacebook = (data) => {
    return {
        type: 'GET_CONNECTIONS_FACEBOOK',
        payload: data
    }
}
export const fetchConnectionFacebook = (id, token) => {
    return (dispatch) => {
        facebookConnectionsController.abort();
        facebookConnectionsController = new AbortController()
        getRequestParamProtected(`locations/${id}/redirect/connect/facebook`, { token }, getConnectionFacebook, dispatch, facebookConnectionsController)
    }
}
export const clearConnectionFacebook = () => {
    return {
        type: "CLEAR_CONNECTION_FACEBOOK"
    }
}
//End of Location Connections Facebook

//Location Connections Google
export const getConnectionGoogle = (data) => {
    return {
        type: 'GET_CONNECTIONS_GOOGLE',
        payload: data
    }
}
export const fetchConnectionGoogle = (id, code) => {
    return (dispatch) => {
        googleConnectionsController.abort();
        googleConnectionsController = new AbortController()
        getRequestParamProtected(`locations/${id}/redirect/connect/google`, { code }, getConnectionGoogle, dispatch, googleConnectionsController)
    }
}
export const clearConnectionGoogle = () => {
    return {
        type: "CLEAR_CONNECTION_GOOGLE"
    }
}
//End of Location Connections Google

//Location Connections Source
export const getConnectionSource = (data, source) => {
    return {
        type: 'GET_CONNECTIONS_SOURCE',
        payload: data,
        source: source
    }
}
export const fetchConnectionSource = (id, source) => {
    return (dispatch) => {
        sourceConnectionsController.abort();
        sourceConnectionsController = new AbortController()
        getRequestParamProtected(`locations/${id}/redirect/connect/${source}`, {}, getConnectionSource, dispatch, sourceConnectionsController)
    }
}
export const clearConnectionSource = (source) => {
    return {
        type: "CLEAR_CONNECTION_SOURCE",
        source: source
    }
}
//End of Location Connections Source

//Subscriptions
export const getSubscriptions = (data) => {
    return {
        type: 'GET_SUBSCRIPTION_DETAILS',
        payload: data
    }
}
export const fetchSubscriptions = () => {
    return (dispatch) => {
        getRequestProtected('subscription', getSubscriptions, dispatch)
    }
}
export const clearSubscriptions = () => {
    return {
        type: "CLEAR_SUBSCRIPTIONS"
    }
}
//End of Subscriptions

//Categories For Signup
export const categoriesActionSp = (data) => {
    return {
        type: 'GET_CATEGORIES_SP',
        payload: data
    }
}

export const fetchCategoriesSp = () => {
    return (dispatch) => {
        getRequest('business/category', categoriesActionSp, dispatch)
    }
}

//Change Web Language

export const irevuMultiLanguage = (lg) => {
    return{
        type: 'LANGUAGE',
        payload: lg
    }
}

//GET SUBSCRIPTION VALUES
export const getSubscriptionValues = (data) => {
    return {
        type: "GET_SUBSCRIPTION_VALUES",
        payload: data
    }
}

export const clearSubscriptionValues = (data) => {
    return {
        type: "CLEAR_SUBSCRIPTION_VALUES"
    }
}

export const editSubscriptionCard = (data, id, subId) => {
    return {
        type: "EDIT_SUBSCRIPTION_CARD",
        payload:{data, id, subId}
    }
}

export const deleteSubscriptionCard = (cardId) => {
    return {
        type: 'DELETE_SUBSCRIPTION_CARD',
        payload: { cardId }
    }
};

export const fetchSubscriptionValues = (locations, plan, period, aLaCarte) => {
    return (dispatch) => {
        controllerSubscriptionValues.abort();
        controllerSubscriptionValues = new AbortController()
        getRequestParamProtected('subscription/get-subscription-data', {location_ids: locations, plan_id: plan, period,  laCarte_amount: aLaCarte}, getSubscriptionValues, dispatch, controllerSubscriptionValues)
    }
}

//GET WALLET
export const getWallet = (data) => {
    return {
        type: "GET_WALLET",
        payload: data
    }
}
export const clearWallet = (data) => {
    return {
        type: "CLEAR_WALLET"
    }
}

export const fetchWallet = () => {
    return (dispatch) => {
        getRequestPostProtected('wallet/info', {}, getWallet, dispatch)
    }
}

//Marketing Materials Options Selected

export const getOptionsSelected = (orders) => {
    return{
        type: "GET_MARKETING_ORDERS_SELECTED",
        payload: orders
    }
}

export const removeOptionsSelected = (orders) => {
    return{
        type: "REMOVE_MARKETING_ORDER",
        payload: orders
    }
}

export const clearOptionsSelected = (orders) => {
    return{
        type: "CLEAR_MARKETING_ORDER",
        payload: orders
    }
}

//Get Marketing Materials
export const getMarketingMaterialOptions = (data) => {
    return {
        type: "GET_MARKETING_MATERIAL_OPTIONS",
        payload: data
    }
}

export const clearMarketingMaterialOptions  = (data) => {
    return {
        type: "CLEAR_MARKETING_MATERIAL_OPTIONS"
    }
}

export const fetchMarketingMaterialOptions = (id) => {
    return (dispatch) => {
        getRequestProtected(`marketing-materials/${id}`, getMarketingMaterialOptions, dispatch)
    }
}

export const uploadImageMaterial = (id, imageType, imageUrl, path) => {
    return {
        type: 'UPLOAD_IMAGES',
        payload: {
            id,
            imageType,
            imageUrl,
            path
        }
    };
};

export const changePositionMarketing = (id,positionOptionId) => {
    return {
        type: 'CHANGE_POSITION',
        payload: {
            id_type: id,
            positionOptionId
        }
    };
};

//Get pre-uploaded-images
export const getPreUploadedImages = (data) => {
    return {
        type: "GET_PRE_UPLOADED_IMAGES",
        payload: data
    }
}
export const addPreUploadedImages  = (img) => {
    return {
        type: "ADD_PRE_UPLOADED_IMAGES",
        payload: img
    }
}

export const clearPreUploadedImages  = (data) => {
    return {
        type: "CLEAR_PRE_UPLOADED_IMAGES"
    }
}
export const deletePreUploadedImages  = (id) => {
    return {
        type: "DELETE_PRE_UPLOADED_IMAGES",
        payload: id
    }
}

export const fetchPreUploadedImages = () => {
    return (dispatch) => {
        controllerPreUploadedImages.abort();
        controllerPreUploadedImages = new AbortController()
        getRequestParamProtected('marketing-materials/pre-uploaded-images',{}, getPreUploadedImages, dispatch, controllerPreUploadedImages)
    }
}

//Get discovered images
export const getDiscoverImages = (data) => {
    return {
        type: "GET_DISCOVER_IMAGES",
        payload: data
    }
}

export const deleteDiscoverImages  = (id) => {
    return {
        type: "DELETE_DISCOVER_IMAGES",
        payload: id
    }
}

export const fetchDiscoverImages = () => {
    return (dispatch) => {
        getRequestProtected('marketing-materials/discover-images', getDiscoverImages, dispatch)
    }
}
//Get Cart
export const getCart = (data) => {
    return{
        type: "GET_CART",
        payload: data
    }
}
export const deleteCartItem = (id,summary) => {
    return{
        type: "DELETE_CART_ITEM",
        payload: {
            id, 
            summary
        }
    }
}
export const editCartQuantity = (id, quantity) => {
    return{
        type: "EDIT_QUANTITY_ITEM",
        payload: {
            id,
            quantity
        }
    }
}
export const editSubtotalItem = (id, total_with_options) => {
    return{
        type: "EDIT_SUBTOTAL_ITEM",
        payload: {
            id,
            total_with_options
        }
    }
}
export const editCardTotal = (summary) => {
    return{
        type: "EDIT_CART_TOTAL",
        payload: {
            summary
        }
    }
}
export const editCartOptions = (id, options) => {
    return{
        type: "EDIT_CART_OPTIONS",
        payload: {
            id,
            options
        }
    }
}
export const clearCart = () => {
    return{
        type: "CLEAR_CART"
    }
}
export const fetchCart = (id, shipping_service_id) => {
    return(dispatch) => {
        const params = {};
        if (shipping_service_id) {
            params.shipping_service_id = shipping_service_id;
        }
        getRequestParamProtected(`marketing-materials/cart/${id}`, params, getCart, dispatch)
    }
}

export const editImageMaterial = (id, imageType, imageUrl) => {
    return {
        type: 'EDIT_IMAGES',
        payload: {
            id,
            imageType,
            imageUrl
        }
    };
};

//Get Marketing Materials Shipping
export const getShippingServices = (data) => {
    return{
        type: "GET_SHIPPING_SERVICES",
        payload: data
    }
}

export const fetchShippingService = () => {
    return(dispatch) => {
        getRequestProtected(`marketing-materials/shipping-services`, getShippingServices, dispatch)
    }
}

//Get Orders
export const getOrders = (data) => {
    return{
        type: "GET_ORDERS",
        payload: data
    }
}
export const fetchOrders = (id) => {
    return(dispatch) => {
        getRequestProtected(`marketing-materials/order/index/${id}`, getOrders, dispatch)
    }
}
export const clearOrders = () => {
    return{
        type: "CLEAR_ORDERS",
    }
}
//Get Order
export const getOrder = (data) => {
    return{
        type: "GET_ORDER",
        payload: data
    }
}
export const fetchOrder = (id) => {
    return(dispatch) => {
        getRequestProtected(`marketing-materials/order/show/${id}`, getOrder, dispatch)

    }
}
export const clearOrder = () => {
    return{
        type: "CLEAR_ORDER",
    }
}
//Get Shipping Address Marketing Material
export const getShippingDefaultAdress = (data) => {
    return{
        type: 'GET_SHIPPING_DEFAULT_ADDRESS',
        payload: data
    }
}
export const fetchDefaultAdress = (type) => {
    return(dispatch) => {
        getRequestParamProtected(`me/default-address`,{type}, getShippingDefaultAdress, dispatch)
    }
}
export const clearDefaultAddress = () => {
    return{
        type: 'CLEAR_DEFAULT_ADDRESS',
    }
}
//Get Billing Address
export const getBillingDefaultAdress = (data) => {
    return{
        type: 'GET_BILLING_DEFAULT_ADDRESS',
        payload: data
    }
}
export const fetchDefaultBillingAdress = (type) => {
    return(dispatch) => {
        getRequestParamProtected(`me/default-address`,{type}, getBillingDefaultAdress, dispatch)
    }
}
export const clearDefaultBillingAddress = () => {
    return{
        type: 'CLEAR_DEFAULT_BILLING_ADDRESS',
    }
}

//Get Card Marketing Material
export const getCardMarketingMaterial = (data) => {
    return{
        type: 'GET_CARD_MARKETING_MATERIAL',
        payload: data
    }
}
export const fetchCardMarketingMaterial = () => {
    return(dispatch) => {
        getRequestProtected(`me/default-card`, getCardMarketingMaterial, dispatch)
    }
}

//Check for images
export const getImageCheck = (data) => {
    return{
        type: 'GET_IMAGE_CHECK',
        payload: data
    }
}
export const fetchImageCheck = (id) => {
    return(dispatch) => {
        getRequestParamProtected(`marketing-materials/cart/check`, {location: id}, getImageCheck, dispatch)
    }
}
export const clearImageCheck = () => {
    return{
        type: 'CLEAR_IMAGE_CHECK'
    }
}
//Add Card Id
export const addCardId = (item_id, card_id, price) => {
    return{
        type: "ADD_CARD_ID",
        payload: {
            item_id,
            card_id,
            price
        }
    }
}
//Downloads
export const getDownloads = (data) => {
    return{
        type: "GET_DOWNLOADS",
        payload: data
    }
}
export const fetchDownloads = () => {
    return (dispatch) => {
        getRequestProtected('user/files', getDownloads, dispatch)
    }
}
export const deleteDownload = (id) => {
    return{
        type: "DELETE_DOWNLOAD",
        payload: id
    }
}
export const clearDownloads = (data) => {
    return {
        type: "CLEAR_DOWNLOADS"
    }
}
//Predefined Responses
 
export const getPredefinedResponses = (data) => {
    return{
        type: "GET_PREDEFINED_RESPONSES",
        payload: data
    }
}
export const fetchPredefinesResponses = (id) => {
    return(dispatch) => {
        getRequestParamProtected('responses/pre-defined', {location: id}, getPredefinedResponses, dispatch)
    }
}
export const deletePredefinedResponse = (id) => {
    return{
        type: "DELETE_PREDEFINED_RESPONSE",
        payload: id
    }
}
export const addPredefinedResponse = (items) => {
    return{
        type: "ADD_PREDEFINED_RESPONSE",
        payload: items
    }
}
export const editPredefinedResponse = (item) => {
    return{
        type: "EDIT_PREDEFINED_RESPONSE",
        payload: item
    }
}
export const clearPredefinedResponses = () => {
    return{
        type: "CLEAR_PREDEFINED_RESPONSES"
    }
}
//Wallet Modal
export const changeWalletVisibility = (show) => {
    return{
        type: "CHANGE_WALLET_VISIBILITY",
        payload: show
    }
}

//SOCIAL GET FACEBOOK PAGES
export const getPages = (data) => {
    return{
        type: "GET_PAGES",
        payload: data
    }
}
export const deletePages = (data) => {
    return{
        type: "DELETE_PAGES",
        payload: data
    }
}
export const fetchPages = () => {
    return(dispatch) => {
        getRequestProtected('social/account/index', getPages, dispatch)
    }
}
//END OF SOCIAL GET FACEBOOK PAGES
//GET SOCIAL DASHBOARD
export const clearSocialDashboard = () => {
    return{
        type: "LOADING_SOCIAL_DASHBOARD"
    }
}
export const getSocialDashboard = (data) => {
    return{
        type: "GET_SOCIAL_DASHBOARD",
        payload: data
    }
}
export const fetchSocialDashboard = (id) => {
    let getParams = id != "all" ? {account_type: id} : {}
    return(dispatch) => {
        dispatch(clearSocialDashboard())
        controllerSocialStats.abort()
        controllerSocialStats = new AbortController()
        getRequestParamProtected('social/dashboard', getParams, getSocialDashboard, dispatch, controllerSocialStats)
    }
}
//END OF GET SOCIAL DASHBOARD
//GET SOCIAL REVIEWS LIST
const getSocialReviews = (data) => {
    return {
        type: "GET_SOCIAL_REVIEWS",
        payload: data
    }
}
const loadingSocialReview = () => {
    return{
        type: "LOADING_SOCIAL_REVIEWS"
    }
}
export const fetchSocialReviews = () => {
    return(dispatch) => {
        dispatch(loadingSocialReview())
        getRequestProtected('social/review-auto-post', getSocialReviews, dispatch)
    }
}
const getSocialReview = (data) => {
    return {
        type: "GET_SOCIAL_REVIEW",
        payload: data
    }
}
export const fetchSocialReview = (id) => {
    return(dispatch) => {
        getRequestProtected(`social/review-auto-post/schedule/${id}`, getSocialReview, dispatch)
    }
}
export const addNewReviewSchedule = (item) => {
    return{
        type: "ADD_NEW_REVIEW_SCHEDULE",
        payload: item
    }
}
export const editNewReviewSchedule = (item) => {
    return{
        type: "EDIT_REVIEW_SCHEDULE",
        payload: item
    }
}
export const removeNewReviewSchedule = (id) => {
    return{
        type: "DELETE_REVIEW_SCHEDULE",
        payload: id
    }
}
//END OF SOCIAL REVIEWS LISt
//GET SOCIAL POSTS
export const getSocialPosts = (data) => {
    return{
        type: "GET_SOCIAL_POSTS",
        payload: data
    }
}
export const clearSocialPosts = () => {
    return{
        type: "CLEAR_SOCIAL_POSTS",
    }
}
export const fetchSocialPosts = (status, type, month) => {
    return(dispatch) => {
        controllerSocialPosts.abort();
        controllerSocialPosts = new AbortController()
        dispatch(clearSocialPosts())
        const query_url = `social/posts?status[]=${status}&month=${month}&type=${type}`;
        getRequestProtected(`${query_url}`, getSocialPosts, dispatch, false, undefined, controllerSocialPosts)
    }
}
export const filterPostsByStatus = (status) => {
    return{
        type: "FILTER_POSTS_BY_STATUS",
        payload: status
    }
}
//END OF GET SOCIAL POSTS
//GET META TAGS FROM URL
export const getMetaTags = (data) => {
    return{
        type: "GET_META_TAGS",
        payload: data
    }
}
export const loadingMetaTags = () => {
    return{
        type: "LOADING_META_TAGS"
    }
}
export const fetchMetaTags = (url) => {
    return(dispatch) => {
        dispatch(loadingMetaTags());
        getRequestProtected(`social/meta-tags?url=${url}`,  getMetaTags, dispatch)
    }
}
//END OF GET META TAGS FROM URL
///SOCIAL AUTOMATION FACEBOOK CONNECT
export const getFacebookAccounts = (data) => {
    return{
        type: "GET_FACEBOOK_ACCOUNTS",
        payload: data
    }
}
export const fetchFacebookAccounts = (token) => {
    return(dispatch) => {
        getRequestParamProtected('locations/redirect/connect/facebook', {token}, getFacebookAccounts, dispatch)
    }
}
//END OF SOCIAL FACEBOOK CONNECT

//GET CARDS
export const getCards = (data) => {
    return{
        type: "GET_CARDS",
        payload: data
    }
}
export const AddCard = (data) => {
    return{
        type: "ADD_CARD",
        payload: data
    }
}
export const EditCard = (data) => {
    return{
        type: "EDIT_CARD",
        payload: data
    }
}
export const DeleteCard = (id) => {
    return{
        type: "DELETE_CARD",
        payload: id
    }
}
export const fetchCards = () => {
    return(dispatch) => {
        getRequestProtected('subscription/cards', getCards, dispatch)
    }
}
//END OF GET CARDS

//GET ACHIEVEMENTS
export const getAchievements = (data) => {
    return{
        type: "GET_ACHIEVEMENTS",
        payload: data
    }
}

export const updateAchievements = (data) => {
    return{
        type: "UPDATE_ACHIEVEMENT",
        payload: data
    }
}
export const loadingAchievements = () => {
    return{
        type: "LOADING_ACHIEVEMENTS"
    }
}
export const fetchAchievements = (sources) => {
    return(dispatch) => {
        dispatch(loadingAchievements())
        controllerAchievements.abort()
        controllerAchievements = new AbortController()
        getRequestPostProtected('locations/reviews-next-achievement', {platforms: sources}, getAchievements, dispatch, controllerAchievements)
    }
}
//END OF GET ACHIEVEMENTS

