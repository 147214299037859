import React, { useEffect, useState } from 'react';
import LocAndGroup from '../Admin/parts/LocAndGroup';
import { fetchPermissions, fetchUserInfo, getGroupLocation, getGroupSelected, getLocationSelected } from '../../redux/actions/admin-index';
import { useDispatch } from 'react-redux';
import { Grid, Rating, Skeleton } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LockedReviews from './LockedReviews';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseUrlDev } from '../Helpers/baseUrl';
import { getLocalUser } from '../TokenControl/parts/useLocalUser';

const Locked = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);

    const location = useSelector(state => state.locationSelected.location);
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const groupSelected = useSelector(state => state.groupSelected.group)

    const user = useSelector(state => state.getUserInfo.user);
    const [removeAccountStatus, setRemoveAccountStatus] = useState(false);
    const [logoutStatus, setLogoutStatus] = useState(false);
    let locations = user && user.user.locations;
    const [searchParams] = useSearchParams();
    const locationIdFromQuery = searchParams.get('location_id'); // Get locationId from query params

    useEffect(() => {
        const handleNavigation = () => {
            if (location && location !== "all") {
                findLocation(location);
            } else if (selected) {
                findLocation(selected);
            } else if (groupSelected && !selected && (!location || location === "all")) {
                navigateDashboard();
            } else if (!location || location === "all") {
                navigateDashboard();
            }
        };
    
        if (locations) {
            handleNavigation();
        }
    }, [location, user, groupSelected, selected]);
    
    const findLocation = (id) => {
        const findLoc = locations?.find(loc => loc.id == id);
        if (!findLoc || (findLoc && !findLoc.is_locked)) {
            navigateDashboard();
        }
    };

    const getAllReviews = () => {
        setLoading(true);
        const locationToUse = locationIdFromQuery || location; // Prioritize query params over location

        axios.post(`${baseUrlDev}reviews/locked-location`, { location: locationToUse }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(res => {
            setReviews(res?.data);
            setLoading(false);
        })
            .catch(err => {
                setLoading(false);
            })
    }

    useEffect(() => {
        getAllReviews();
    }, [location, locationIdFromQuery]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigateDashboard = () => {
        navigate('/app/admin', { replace: true });
    }

    useEffect(() => {
        dispatch(fetchUserInfo())
    }, [])

    const logout = () => {
        setLogoutStatus(true);
        axios.post(`${baseUrlDev}logout`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(() => {
                setLogoutStatus(false);
                window.location.href = "https://irevu.com"
                localStorage.clear()
                document.cookie = "i_token=0;domain=.irevu.com;path=/;"
                document.cookie = "irw_token=0;domain=.irevu.com;path=/;"
            })
            .catch(() => {
                setLogoutStatus(false);
            })
    }

    const lockedLocations = locations
        ?.filter(location => location.is_locked)
        ?.map(location => location.full_name)
        ?.join(', ');

    const locationsText = lockedLocations ? `${lockedLocations}` : '';

    useEffect(() => {
        const locationId = searchParams.get('location_id'); // Get locationId from query params
        if (locationId) {
            changeLocation(locationId);
        }
    }, [searchParams]);

    useEffect(() => {
        if(selected && (!location || location == "all")){
            changeLocation(selected)
        }
    }, [selected]);


    const changeLocation = (id) => {
        dispatch(getLocationSelected(id));
        dispatch(getGroupSelected(""));
        dispatch(getGroupLocation(""));

        localStorage.setItem('group', "");
        localStorage.setItem('location', id);
        localStorage.setItem("location_of_group", "");
    };

    const removeAccount = () => {
        setRemoveAccountStatus(true);
        const locationToUse = locationIdFromQuery || location;

        axios.post(`${baseUrlDev}locations/delete/${locationToUse}`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(res => {
            dispatch(getLocationSelected(""));
            dispatch(getGroupSelected(""));
            dispatch(getGroupLocation("all"));
    
            localStorage.setItem('group', "");
            localStorage.setItem('location', "all");
            localStorage.setItem("location_of_group", "");
            
            dispatch(fetchPermissions("all", ""))
            navigateDashboard();
        })
        .catch(err => {
            setRemoveAccountStatus(false);
        })
    }

    return (
        <div className='locked_section_wrapper'>
            <div>
                <div className="top__header">
                    <img src="/admin-images/kore_co_logo_locked.svg" alt="icon" />
                    <div style={{ marginTop: '7px' }}>
                        <LocAndGroup all={true} navigateDashboard={navigateDashboard} sliceText={true} />
                    </div>
                    <button className='sign_out__btn' disabled={logoutStatus}>
                        <img src="/admin-images/sign-out-icon-locked.svg" alt="icon" onClick={logout} />
                    </button>
                </div>

                <div className="locked_content_wrapper">
                    <Grid container spacing={2}>
                        <Grid item lg={6}>
                            <div className="left">
                                <h4 className='review_title'>
                                    {loading ? <Skeleton variant="rectangular" sx={{ height: "15px", width: '15px' }} /> : reviews?.average_rating}
                                    <Rating icon={<img src={"/admin-images/locked-star-icon.svg"} className="custom-rating-ic" />} emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-ic" />} name="half-rating-read" value={parseFloat(reviews?.average_rating?.toFixed(2))} precision={0.5} readOnly style={{ marginRight: "10px" }} />

                                    {/* <div className='star-images'>
                                        <img src="/admin-images/locked-star-icon.svg" alt="icon" />
                                        <img src="/admin-images/locked-star-icon.svg" alt="icon" />
                                        <img src="/admin-images/locked-star-icon.svg" alt="icon" />
                                        <img src="/admin-images/locked-star-icon.svg" alt="icon" />
                                        <img src="/admin-images/locked-star-icon.svg" alt="icon" />
                                    </div> */}
                                </h4>
                                <h3>
                                    Unlock Access and <span>Continue Delivering the Top-Notch Service</span> Your Reviews Praise!
                                </h3>
                                <div className="text-content">
                                    <p>Based on {reviews?.total_reviews} reviews, your customers really like your services, and are willing to come back. </p>
                                    <p>The current selected account <span style={{ display: 'inline-block' }}>{loading ? <Skeleton variant="rectangular" sx={{ height: "15px", width: '15px' }} /> : reviews?.location_name}</span>, and  {locationsText}, are currently locked due to a pending payment. </p>
                                    <p>To regain full access and continue delivering the excellent service your customers love, please complete the payment. Unlock your account today to keep providing the top-notch service that has earned you outstanding reviews.</p>
                                </div>

                                <div className="buttons">
                                    <Link to={`/app/admin/subscription?location=${location}`} className='reactivate-btn'>
                                        <div className="background-overlay-1"></div>
                                        <div className="background-overlay-2"></div>
                                        <img src="/admin-images/reactivate-icon.svg" alt="icon" />
                                        <span>Re-Activate</span>
                                    </Link>
                                    <Link to="/app/admin/support" className='support-btn'>
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.5 0C5.6075 0 0 5.3832 0 12V16.9716C0 18.2004 1.12125 19.2 2.5 19.2H3.75C4.08152 19.2 4.39946 19.0736 4.63388 18.8485C4.8683 18.6235 5 18.3183 5 18V11.8284C5 11.5101 4.8683 11.2049 4.63388 10.9799C4.39946 10.7548 4.08152 10.6284 3.75 10.6284H2.615C3.31 5.9844 7.4725 2.4 12.5 2.4C17.5275 2.4 21.69 5.9844 22.385 10.6284H21.25C20.9185 10.6284 20.6005 10.7548 20.3661 10.9799C20.1317 11.2049 20 11.5101 20 11.8284V19.2C20 20.5236 18.8787 21.6 17.5 21.6H15V20.4H10V24H17.5C20.2575 24 22.5 21.8472 22.5 19.2C23.8787 19.2 25 18.2004 25 16.9716V12C25 5.3832 19.3925 0 12.5 0Z" fill="#333333" />
                                        </svg>
                                        Contact Support
                                    </Link>
                                </div>

                                <button className='remove_from_account' disabled={removeAccountStatus} onClick={removeAccount}>Remove from My Account Permanently</button>
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div className='locked_reviews_gradient'>
                                <span className='locked_reviews_gradient_top'></span>
                                <div className="locked_reviews">
                                    <LockedReviews reviews={reviews?.data} loading={loading} />
                                </div>
                                <span className='locked_reviews_gradient_bottom'></span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Locked
