import React from 'react'
import { ClickAwayListener, Collapse, FormControl, Skeleton } from '@mui/material'
import { useEffect, useState, useMemo } from 'react'
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getLocationSelected, getGroupSelected, getGroupLocation } from '../../../redux/actions/admin-index';
import { useTranslation } from 'react-i18next';

const containsTextLocations = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;
const containsTextGroups = (group, search) => {
    const groupNameMatches = group.name.toLowerCase().indexOf(search.toLowerCase()) > -1;

    if (groupNameMatches) {
        return { ...group };
    }

    const matchingLocations = group.locations.filter(location =>
        Object.values(location).some(value =>
            typeof value === 'string' && value.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
    );

    return matchingLocations.length > 0 ? { ...group, locations: matchingLocations } : null;
};

const LocAndGroup = ({ all, groups, disable_location, navigateDashboard, sliceText }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const nav = useLocation()
    const [searchLocations, setSearchLocations] = useState("");
    const [show, setShow] = useState(false)
    const [activeTab, setActiveTab] = useState("groups")

    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations
    let groupsArray = user && user.user.groups

    const locationSelected = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)

    const [t] = useTranslation()

    useEffect(() => {
        if (locations) {
            if (locations.length == 0) {
                navigate("/create/location")
            }
            if (!all && locations.length == 1) {
                if (!locationSelected || locationSelected === "all") {
                    dispatch(getLocationSelected(locations[0].id))
                    localStorage.setItem('location', locations[0].id);
                    dispatch(getGroupSelected(""))
                    localStorage.setItem('group', "");
                }

            } else {
                if ((!locationSelected || locationSelected === "all") && !groupSelected) {
                    dispatch(getLocationSelected("all"))
                    localStorage.setItem('location', "all");
                    dispatch(getGroupSelected(""))
                    localStorage.setItem('group', "");
                }
            }
        }
    }, [user, nav])

    // useEffect(() => {
    //     if(groupSelected){
    //         setActiveTab("groups")
    //     } else {
    //         setActiveTab("locations")
    //     }
    // }, [groupSelected])

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [show]);

    //   useEffect(() => {
    //     if(!groups){
    //         setActiveTab("locations")
    //     }
    // }, [groups])


    const displayedGroups = useMemo(() => groupsArray && groupsArray.map(group => containsTextGroups(group, searchLocations)).filter(Boolean), [searchLocations, user]);


    if (!user || !locations) {
        return (
            <FormControl sx={{ width: '10vw' }}>
                <Skeleton style={{ height: '37px', transform: 'unset' }} />
            </FormControl>
        )
    }

    const changeLocation = (id) => {
        dispatch(getLocationSelected(id))
        dispatch(getGroupSelected(""))
        dispatch(getGroupLocation(""))

        localStorage.setItem('group', "");
        localStorage.setItem('location', id);
        localStorage.setItem("location_of_group", "")

        setShow(false)
    }

    const changeGroup = (id) => {
        dispatch(getGroupSelected(id))
        dispatch(getLocationSelected(""))
        dispatch(getGroupLocation(""))

        localStorage.setItem('location', "");
        localStorage.setItem('group', id);
        localStorage.setItem("location_of_group", "")

        setShow(false)
    }

    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <div className={disable_location ? "disabled_location_wrapper" : ""}>
                <FormControl className="select-search-loc">
                    <div onClick={() => setShow(!show)} className='select-search-header' style={{ borderColor: show ? "#18AEE3" : "rgba(37, 49, 60, 0.15)" }}>
                        {locationSelected ?
                            (locations && locations.filter(loc => loc.id == locationSelected) && locations.filter(loc => loc.id == locationSelected).length > 0 ?
                                <>
                                    {sliceText
                                        ? locations.filter(loc => loc.id == locationSelected)[0].full_name.slice(0, 25)
                                        : locations.filter(loc => loc.id == locationSelected)[0].full_name
                                    }
                                    { sliceText && locations.filter(loc => loc.id == locationSelected)[0].is_locked && (
                                        <span className='locked_span'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
                                                <path d="M6.28264 3.59968V2.44816C6.28171 1.82557 6.03398 1.22874 5.59374 0.788505C5.1535 0.348268 4.55668 0.100535 3.93409 0.0996094H3.73109C3.1085 0.100535 2.51167 0.348268 2.07144 0.788505C1.6312 1.22874 1.38347 1.82557 1.38254 2.44816V3.59968C1.10406 3.59968 0.836981 3.71031 0.640064 3.90722C0.443146 4.10414 0.33252 4.37122 0.33252 4.6497V7.90476C0.333445 8.4336 0.543933 8.94051 0.917875 9.31445C1.29182 9.68839 1.79873 9.89888 2.32756 9.8998H5.33762C5.86645 9.89888 6.37336 9.68839 6.7473 9.31445C7.12125 8.94051 7.33173 8.4336 7.33266 7.90476V4.6497C7.33266 4.37122 7.22203 4.10414 7.02512 3.90722C6.8282 3.71031 6.56112 3.59968 6.28264 3.59968ZM2.08255 2.44816C2.08255 2.01094 2.25624 1.59163 2.5654 1.28247C2.87456 0.973308 3.29387 0.799623 3.73109 0.799623H3.93409C4.37131 0.799623 4.79062 0.973308 5.09978 1.28247C5.40894 1.59163 5.58262 2.01094 5.58262 2.44816V3.59968H2.08255V2.44816ZM6.63265 7.90476C6.63265 8.24823 6.49621 8.57762 6.25334 8.82049C6.01048 9.06335 5.68108 9.19979 5.33762 9.19979H2.32756C1.9841 9.19979 1.6547 9.06335 1.41184 8.82049C1.16897 8.57762 1.03253 8.24823 1.03253 7.90476V4.6497C1.03253 4.55687 1.06941 4.46785 1.13505 4.40221C1.20069 4.33657 1.28971 4.29969 1.38254 4.29969H6.28264C6.37547 4.29969 6.46449 4.33657 6.53013 4.40221C6.59577 4.46785 6.63265 4.55687 6.63265 4.6497V7.90476Z" fill="black" />
                                                <path d="M4.18235 6.6539V7.80192C4.18235 7.89475 4.14547 7.98378 4.07983 8.04942C4.01419 8.11505 3.92517 8.15193 3.83234 8.15193C3.73951 8.15193 3.65049 8.11505 3.58485 8.04942C3.51921 7.98378 3.48233 7.89475 3.48233 7.80192V6.6539C3.34888 6.57685 3.24458 6.45792 3.18561 6.31555C3.12664 6.17319 3.11629 6.01534 3.15618 5.86649C3.19606 5.71765 3.28394 5.58612 3.4062 5.49231C3.52845 5.3985 3.67824 5.34766 3.83234 5.34766C3.98644 5.34766 4.13623 5.3985 4.25848 5.49231C4.38073 5.58612 4.46862 5.71765 4.5085 5.86649C4.54838 6.01534 4.53804 6.17319 4.47907 6.31555C4.4201 6.45792 4.3158 6.57685 4.18235 6.6539Z" fill="black" />
                                            </svg>
                                            LOCKED
                                        </span>
                                    )}
                                </>
                                :
                                t("All Groups"))
                            :
                            (groupsArray && groupsArray.filter(group => group.id == groupSelected) && groupsArray.filter(group => group.id == groupSelected).length > 0 ?
                                `Group - ${groupsArray.filter(group => group.id == groupSelected)[0].name}`
                                :
                                t("All Groups"))
                        }
                    </div>
                </FormControl>
                <Collapse in={show}>
                    <div className='loc-group-container-wrapper'>
                        <div className="loc-group-tabs">
                            {/* {groups && 
                                <div>
                                    <div className={activeTab === "locations" ? "active" : ""} onClick={() => setActiveTab("locations")}>
                                        {t("Locations")}
                                    </div>
                                    <div className={activeTab === "groups" ? "active" : ""} onClick={() => setActiveTab("groups")}>
                                        {t("Groups")}
                                    </div>
                                </div>
                            } */}
                        </div>
                        <input type="text" placeholder={t("Search Group Or Location")} className='search-inside-dropdown'
                            onChange={(e) => setSearchLocations(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                }
                            }}
                        />
                        {/* <Collapse in={activeTab === "locations"}>
                            <div className='loc-group-container custom-scrollbar-vertical'>
                                {all  &&  locations.length > 1 && 
                                    <div>
                                        <input type="radio" name="location" value={"all"} id={"all_locations"} onChange={() =>  changeLocation("all")} checked={locationSelected == "all" ? true : false}/>
                                        <label htmlFor={"all_locations"}>
                                            {t("All Locations")}
                                        </label>
                                    </div>
                                }

                                {displayedLocation && displayedLocation.map(loc => {
                                    return(
                                        <div key={loc.id} className="left-loc-icon">
                                            <input type="radio" name="location" value={loc.id} id={loc.id} onChange={() =>  changeLocation(loc.id)} checked={locationSelected == loc.id  ? true : false}/>
                                            <label htmlFor={loc.id}>
                                                {loc.full_name}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse> */}
                        <Collapse in={activeTab === "groups"}>
                            <div className='loc-group-container custom-scrollbar-vertical'>
                                <div className="element-container">
                                    <input type="radio" name="groups" value={"all"} id={"all_groups"} onChange={() => changeLocation("all")} checked={locationSelected == "all" ? true : false} />
                                    <label htmlFor={"all_groups"}>
                                        {t("All Groups")}
                                    </label>
                                </div>
                                {displayedGroups && displayedGroups.map(group => {
                                    return (
                                        <div key={`${group.id}-group-of-user`}>
                                            <div className="left-loc-icon-gr element-container">
                                                <input type="radio" name="location" value={group.id} id={group.id} onChange={() => changeGroup(group.id)} checked={groupSelected == group.id ? true : false} />
                                                <label htmlFor={group.id}>
                                                    {t("Group")} - {group.name}
                                                </label>
                                            </div>
                                            <div className={`group-locations ${groupSelected == group.id ? "group-locations-selected" : ""}`}>
                                                {group.locations.map((loc, locIndex) => (
                                                    <div key={`${loc.id}-lof-of-gr`} className="left-loc-icon element-container">
                                                        <input type="radio" name="location" value={loc.id} id={loc.id} onChange={() => changeLocation(loc.id)} checked={locationSelected == loc.id ? true : false} />
                                                        <label htmlFor={loc.id}>
                                                            {loc.full_name}
                                                            {group.locations[locIndex]?.is_locked && (
                                                                <span className='locked_span' key={Math.random()}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
                                                                        <path d="M6.28264 3.59968V2.44816C6.28171 1.82557 6.03398 1.22874 5.59374 0.788505C5.1535 0.348268 4.55668 0.100535 3.93409 0.0996094H3.73109C3.1085 0.100535 2.51167 0.348268 2.07144 0.788505C1.6312 1.22874 1.38347 1.82557 1.38254 2.44816V3.59968C1.10406 3.59968 0.836981 3.71031 0.640064 3.90722C0.443146 4.10414 0.33252 4.37122 0.33252 4.6497V7.90476C0.333445 8.4336 0.543933 8.94051 0.917875 9.31445C1.29182 9.68839 1.79873 9.89888 2.32756 9.8998H5.33762C5.86645 9.89888 6.37336 9.68839 6.7473 9.31445C7.12125 8.94051 7.33173 8.4336 7.33266 7.90476V4.6497C7.33266 4.37122 7.22203 4.10414 7.02512 3.90722C6.8282 3.71031 6.56112 3.59968 6.28264 3.59968ZM2.08255 2.44816C2.08255 2.01094 2.25624 1.59163 2.5654 1.28247C2.87456 0.973308 3.29387 0.799623 3.73109 0.799623H3.93409C4.37131 0.799623 4.79062 0.973308 5.09978 1.28247C5.40894 1.59163 5.58262 2.01094 5.58262 2.44816V3.59968H2.08255V2.44816ZM6.63265 7.90476C6.63265 8.24823 6.49621 8.57762 6.25334 8.82049C6.01048 9.06335 5.68108 9.19979 5.33762 9.19979H2.32756C1.9841 9.19979 1.6547 9.06335 1.41184 8.82049C1.16897 8.57762 1.03253 8.24823 1.03253 7.90476V4.6497C1.03253 4.55687 1.06941 4.46785 1.13505 4.40221C1.20069 4.33657 1.28971 4.29969 1.38254 4.29969H6.28264C6.37547 4.29969 6.46449 4.33657 6.53013 4.40221C6.59577 4.46785 6.63265 4.55687 6.63265 4.6497V7.90476Z" fill="black" />
                                                                        <path d="M4.18235 6.6539V7.80192C4.18235 7.89475 4.14547 7.98378 4.07983 8.04942C4.01419 8.11505 3.92517 8.15193 3.83234 8.15193C3.73951 8.15193 3.65049 8.11505 3.58485 8.04942C3.51921 7.98378 3.48233 7.89475 3.48233 7.80192V6.6539C3.34888 6.57685 3.24458 6.45792 3.18561 6.31555C3.12664 6.17319 3.11629 6.01534 3.15618 5.86649C3.19606 5.71765 3.28394 5.58612 3.4062 5.49231C3.52845 5.3985 3.67824 5.34766 3.83234 5.34766C3.98644 5.34766 4.13623 5.3985 4.25848 5.49231C4.38073 5.58612 4.46862 5.71765 4.5085 5.86649C4.54838 6.01534 4.53804 6.17319 4.47907 6.31555C4.4201 6.45792 4.3158 6.57685 4.18235 6.6539Z" fill="black" />
                                                                    </svg>
                                                                    LOCKED
                                                                </span>
                                                            )}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse>
                    </div>
                </Collapse>
            </div>
        </ClickAwayListener>
    )
}
export default LocAndGroup